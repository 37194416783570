.KandaHome {
    display: flex;
    flex-direction: row-reverse;
    width: 82.5%;
}
.KandaFeedClass {
    display: flex;    
    justify-content: center;    
}

.KandaFeedClass .KandaFeed-element {
    background: 'white'; 
    border-radius: '16px'; 
    box-shadow: '0px 0px 30px rgba(41, 41, 41, 0.06)';
    z-index: '1';
}
.KandaProfile {
    display: none;        
}
.KandaProfileWeb {
    margin-top: 55px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width: 1255px) {
    #kandaDesktop {
        visibility: hidden;
    }
    
}
@media screen and (max-width: 768px) {
    .KandaHome {
        flex-direction: row;
        justify-content: center;
        width: 100%;
    }
    .KandaFeedClass {
        display: none;    
    }
    .KandaProfile {
        display: flex;    
        justify-content: center;        
    }
    #KandaFeed {
        margin-left: 20%;
    }
    #KandaAbout {
        margin-left: 25%;
        margin-top: 35%;
        justify-content: center;
    }
}
@media screen and (max-width: 542px) {
    #KandaFeed {
        margin-left: -10%;
    }
    #KandaAbout {
        margin-left: -2.5%;
        margin-top: 20%;
    }
}
@media screen and (max-width: 360px) {
    #KandaFeed {
        margin-left: -12.5%;
        margin-top: 25%;

    }
    #KandaAbout {
        margin-left: -8%;
        margin-top: 35%;
    }
}
    