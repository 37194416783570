/*

[1] ----- Columns    ---------------------------------------
    [1.1] ---- Triangle dialog    --------------------------

[2] ----- .Coach Column    ---------------------------------
    [2.1] ---- Coach Image container option 1    -----------
    [2.2] ---- Coach Image container option 2    -----------

[3] ----- .Content Column    -------------------------------
    [3.1] ---- .Go Back Button    --------------------------
    [3.2] ---- .Text Content    ----------------------------
        [3.2.1] ---- .Title    -----------------------------
        [3.2.2] ---- .Description    -----------------------
        [3.2.3] ---- .Instructions    ----------------------
    [3.3] ---- Form    -------------------------------------
        [3.3.1] ---- Input    ------------------------------
        [3.3.2] ---- Select    -----------------------------
        [3.3.3] ---- .Img Button    ------------------------
        [3.3.f] ---- .Custom Text Button : gray text    ----
        [3.3.f] ---- .Custom Button : pink background   ----
    [3.4] ---- Table    ------------------------------------
    [3.5] ---- Shared    -----------------------------------
        [3.5.1] ---- .Circular image    --------------------
*/

.no-margin {
    margin: 0 !important;
}

.custom-step-layout {
    display: flex;
    font-family: 'Roboto';
}

/* - - - - - - [1] Columns - - - - - - */
.custom-step-layout .coach-column {
    width: 22%;
}

.custom-step-layout .content-column {
    width: 78%;
}

.custom-step-layout.c15-85 .coach-column {
    width: 15%;
}

.custom-step-layout.c15-85 .content-column {
    width: 85%;
}

/* ------ [1.1] Triangle dialog ------*/
.custom-step-layout .content-column .triangle {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: -0.9em;
    border-left: 2.1em solid transparent;
    border-right: 0 solid transparent;
    border-top: 60px solid #ffffff;
}


/* - - - - - - [2] Coach Column - - - - - - */
.custom-step-layout .coach-column .coach-c {
    padding-right: 1em;
}

/* ------ [2.1] Coach Image container option 1 ------*/
.custom-step-layout .coach-column .coach-c .img-container {
    position: relative;
    z-index: 1;
    border: 2px solid;
    border-radius: 40%;
    padding: 4px;
    max-width: 80px;
    margin: 0 auto;
}

.custom-step-layout .coach-column .coach-c .img-container > img {
    width: 100%;
    border-radius: 40%;
    overflow: hidden;
}

.custom-step-layout .coach-column .coach-c .img-container .coru-logo {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    right: -3px;
    bottom: -3px;
    padding: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.custom-step-layout .coach-column .coach-c .img-container .coru-logo img {
    width: 100%;
}

/* ------ [2.2] Coach Image container option 2 ------*/
.custom-step-layout .coach-column .logo-coach {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
}

/* - - - - - - [3] Content Column - - - - - - */
.custom-step-layout .content-column {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0 1px 12px #00000014;
    padding: 1.2em;
    position: relative;
    margin-top: 2em;
}

/* ------ [3.1] Go Back Button ------*/
.custom-step-layout .content-column .go-back {
    margin-bottom: 1em;
    margin-top: -5px;
}

.custom-step-layout .content-column .go-back button {
    padding: 0;
    background-color: transparent;
    border: none;
    color: #CDCDCD;
    transition: all .2s linear;
    font-size: 1rem;
}

.custom-step-layout .content-column .go-back button.active {
    color: #F64282;
    font-weight: bold;
}

/* ------ [3.2] Text content ------*/
.custom-step-layout .content-column .text-content {
    margin-bottom: 1em;
}

/* ------ [3.2.1] Title ------*/
.custom-step-layout .content-column .text-content .title {
    font-size: 1.4rem;
    font-weight: bold;
    color: #222222;
    margin-bottom: .5em;
}

/* ------ [3.2.2] Description ------*/
.custom-step-layout .content-column .text-content .description {
    color: #222222;
    font-size: 1rem;
}

/* ------ [3.2.3] Instructions ------*/
.custom-step-layout .content-column form .instructions {
    font-weight: bold;
    color: #222222;
    font-size: 1rem;
    margin-bottom: 2px;
}

/* ------ [3.3] Form ------*/
.custom-step-layout form {
    display: flex;
    flex-direction: column;
}

/* ------ [3.3.1] Input ------*/
.custom-step-layout .content-column form .input-row {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
    margin-bottom: 1em;
}

.custom-step-layout .content-column form input,
.custom-step-layout .content-column form select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #CDCDCD;
    padding: 1em;
    font-size: 1rem;
    flex: 1;
}

/* Chrome, Safari, Edge, Opera */
.custom-step-layout .content-column form input::-webkit-outer-spin-button,
.custom-step-layout .content-column form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.custom-step-layout .content-column form input[type=number] {
    -moz-appearance: textfield;
}

/* ------ [3.3.2] Select ------*/
.custom-step-layout .content-column form select { 
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
}

/* ------ [3.3.3] Img Button ------*/
.custom-step-layout .content-column button.img-button {
    background-color: transparent;
    border: none;
    max-width: 120px;
    flex: 1;
    border-radius: 50%;
    overflow: hidden;
}

.custom-step-layout .content-column button.img-button img {
    width: 100%;
    border: 3px solid transparent;
    border-radius: 50%;
    transition: all 0.2s linear;
}

.custom-step-layout .content-column button.img-button.active img {
    border-color: #F64282;
    padding: 4px;
}

/* ------ [3.3.f] Custom Text Button : gray text ------*/
.custom-step-layout .content-column .button-container {
    text-align: right;
}

.custom-step-layout .content-column button.custom-text-button {
    background-color: transparent;
    border: none;
    color: #CDCDCD;
    transition: all .2s linear;
    font-size: 1rem;
}

.custom-step-layout .content-column button.custom-text-button.active {
    color: #F64282;
    font-weight: bold;
}


/* ------ [3.3.f] Custom Button : pink background ------*/
.custom-step-layout .content-column button.custom-button {
    width: 100%;
    background-color: #F64282;
    border: none;
    border-radius: 4px;
    color: #fafafa;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    padding: .5em;
}

.custom-step-layout .content-column button.custom-button:active {
    background-color: #ff5884;
    border-radius: 4px;
    outline: 0 !important;
    border: none;
}

.custom-step-layout .content-column button.custom-button:focus {
    outline: 0 !important;
}

/* ------ [3.4] Shared ------*/

/* ------ [3.4.1] .Circular Image ------*/
.custom-step-layout .content-column .circular-image{
    border-radius: 50%;
    overflow: hidden;
}

.custom-step-layout .content-column .circular-image img{
    width: 100%;
}

/* ------ [3.4.2] Checkbox ------*/
.custom-step-layout .content-column .check-option {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-step-layout .content-column .check-option input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.custom-step-layout .content-column .check-option .checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    background-color: #eee;
    transition: all 0.1s linear;
}

.custom-step-layout .content-column .check-option p {
    font-size: 1rem;
}

.custom-step-layout .content-column .check-option:hover input ~ .checkbox {
    background-color: #ccc;
}

.custom-step-layout .content-column .check-option input:checked ~ .checkbox {
    background-color: #2196F3;
}
  
.custom-step-layout .content-column .check-option .checkbox:after {
    content: "";
    position: absolute;
    display: none;
}
  
.custom-step-layout .content-column .check-option input:checked ~ .checkbox:after {
    display: block;
}
  
.custom-step-layout .content-column .check-option .checkbox:after {
    left: 11px;
    top: 4px;
    width: 9px;
    height: 18px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width: 770px) {
    
    /* ------ [2.1] Coach Image container option 1 ------*/
    .custom-step-layout .coach-column .coach-c .img-container {
        max-width: 100%;
        margin: 0 auto;
    }

    .custom-step-layout .coach-column .coach-c .img-container {
        border-radius: 40%;
    }

    .custom-step-layout .coach-column .coach-c .img-container > img {
        border-radius: 40%;
    }
    
    .custom-step-layout .coach-column .coach-c .img-container .coru-logo {
        width: 25px;
        height: 25px;
    }

    /* ------ [2.1] Coach Image container option 1 ------*/
    .custom-step-layout .coach-column .logo-coach {
        width: 35px;
        height: 35px;
        padding: .5em;
    }
    

    /* - - - - - - [3] Content Column - - - - - - */
    .custom-step-layout .content-column {
        margin-top: 0;
    }

    /* ------ [3.1] Go Back Button ------*/
    .custom-step-layout .content-column .go-back {
        margin-bottom: .5em;
    }

    .custom-step-layout .content-column .go-back button {
        font-size: .8rem;
    }

    /* ------ [3.3.1] Title ------*/
    .custom-step-layout .content-column .text-content .title {
        font-size: 4.6vw;
    }

    /* ------ [3.3.2] Description ------*/
    .custom-step-layout .content-column .text-content .description {
        color: #222222;
        font-size: .9rem;
        margin-bottom: 1em;
    }

    /* ------ [3.3.3] Instructions ------*/
    .custom-step-layout .content-column form .instructions {
        font-size: .9rem;
    }

    /* ------ [3.3.1] Input ------*/
    .custom-step-layout .content-column form .input-row {
        margin-bottom: .8em;
    }

    .custom-step-layout .content-column form input,
    .custom-step-layout .content-column form select {
        font-size: .9rem;
    }

    .custom-step-layout .content-column form button.img-button {
        max-width: 90px;
    }

    .custom-step-layout .content-column form button.img-button img {
        border: 2px solid transparent;
    }

    /* ------ [3.4.2] Checkbox ------*/
    .custom-step-layout .content-column .check-option p {
        font-size: .8rem;
    }

    .custom-step-layout .content-column .check-option .checkbox {
        height: 25px;
        width: 25px;
    }

    .custom-step-layout .content-column .check-option .checkbox:after {
        left: 8px;
        top: 1px;
        width: 9px;
        height: 18px;
    }

}

.animation-from-up {
    animation: fromUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

.animation-to-down {
    animation: toDown 1.5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
}

@keyframes fromUp {
    0% {
        transform: translate(0, -200%);
    }
    100% {
        transform: translate(0, 0%);
    }
}

@keyframes toDown {
    0% {
        transform: translate(0, 0%);
    }
    100% {
        transform: translate(0, 200%);
    }
}