.ContCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px 32px;
    
    width: 90%;
    height: 250px;
    margin: auto;
    background: #E0E9FE;
    box-shadow: 0px 4px 20px #C9CEEE;
    border-radius: 16px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
}
#cardSolutions .carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: 320px;
}
#cardSolutions .carousel .carousel-status {
    display: none;
}
#cardSolutions .carousel .control-dots .dot {
    background-color: gray;
}

@media(max-width: 320px) and (max-height: 568px) {

    .ContCard {
        padding: 24px 20px;
        font-size: 14px;
    }
    .ContCard p{
        font-size: 14px !important;
    }
}
