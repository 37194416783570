.MuiAvatar-colorDefault {
    color: #e0e0e0!important;
    background-color: #fff!important;
    -webkit-box-shadow: 0px 2px 3px 1px rgba(0,0,0,.75);
    -moz-box-shadow: 0px 2px 3px 1px rgba(0,0,0,.75);
    box-shadow: 0px 2px 3px 1px rgba(0,0,0,.75);
}

.large2
{
    font-size: 3rem!important;
}

.marginright
{
    margin-right: 3%!important;
}
.marginright6
{
    margin-right: 3%!important;
}
.padding2
{
    padding: 2px!important;
}
.MuiSelect-select.MuiSelect-select
{
    padding-top: 2px!important;
}

.minwidth
{
    min-width: 100px!important;
}

.minwidth40
{
    min-width: 40px!important;
}

.marginTop10
{
    margin-top: 10%!important;
}
.MuiSelect-select.MuiSelect-select
{
    padding-top: 23px!important;
}

.Display
{
    display: block;
}

.NoDisplay
{
    display: none!important;
}

.Size
{
    font-size: 15px!important;
}

.floatR
{
    float: right;
    cursor: pointer;
}

.Back, .Back2
{
    display: none!important;
}

.size50
{
    font-size: 50px!important;
}


.containerMobile
{
    display: none!important;
}
.containerCard {
    margin:auto 20% auto 16.5%;
}


@media (max-width:769px) 
{
    .Back
    {
        display: block!important;
        margin-bottom: 3%;
        margin-top: 3%;
    }
    .Back2
    {
        display: block!important;
        margin:3%;
    }

    .containerWeb
    {
        display: none!important;
    }
    .containerMobile
    {
        display: flex!important;
    }

    .mobileWith
    {
        width: 50%!important;
        flex: 0 0 50%!important;
        max-width: 50%!important;
    }

    .mobileWithChicos
    {
        width: 100%!important;
        flex: 0 0 100%!important;
        max-width: 100%!important;
    }
    .containerCard {
        margin:0px !important;
    }
    .containerCard .flex-column{
        min-width: 93% !important;
    }
}


@media (min-width:770px) 
{
    .mobileWithChicos
    {
        width: 70%!important;
        flex: 0 0 70%!important;
        max-width: 70%!important;
    }
}


.Centrar
{
    margin-top: 15%!important;
    margin-bottom: 15%!important;
}

.MuiAvatar-img
{
    border-radius: 35%!important;
    width: 90%!important;
    height: 90%!important;
}

.resumen >.MuiSvgIcon-root
{
    color: #07210A!important;
    width: 17px;
    
}

@media (min-width:771px) 
{
    .WithNoCarrusel
    {
        width: 80%!important;
    }
}
@media (max-width:770px) 
{
    .WithNoCarrusel
    {
        width: 100%!important;
    }
}

@media (max-width:770px) 
{
    .mobileLapiz
    {
       display: block;
    }
}

@media (min-width:771px) 
{
    .mobileLapiz
    {
       display: none;
    }
}



