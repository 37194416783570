.sh-wo-header {
   width: 100%;
   display: flex;
   padding: 1em;
   position: fixed;
   top: 0;
   left: 0;
   z-index: 999;
}

.sh-wo-header .logo {
    width: 15%;
    /* text-align: center; */
}

.sh-wo-header .logo img {
    width: 100%;
    max-width: 120px;
}

.sh-wo-header .browser-options {
    width: 85%;
    display: flex;
    justify-content: flex-end;
}

.sh-wo-header .browser-options ul {
    margin: 0;
}

.sh-wo-header .browser-options ul li {
    display: inline;
    margin: 0 1em;
}

.sh-wo-header .browser-options ul li a {
    font-size: 1rem;
    color: #222222;
}

.sh-wo-header .browser-options ul li button {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    background-color: transparent;
    color: #222222;
    border: 0 solid transparent;
}

.sh-wo-header .browser-options ul li img {
    max-width: 30px;
}

.sh-wo-header .mobile-options {
    display: none;
}

.sh-wo-header .mobile-options-button {
    display: none;
}

@media only screen and (max-width: 770px) {

    .sh-wo-header {
        padding: 1.3em;
     }

    .sh-wo-header .browser-options {
        display: none;
    }

    .sh-wo-header .logo {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* text-align: center; */
    }

    .sh-wo-header .logo img {
        width: 100%;
        max-width: 100px;
    }

    .sh-wo-header .mobile-options-button {
        width: 70%;
        display: flex;
        justify-content: flex-end;
    }

    .sh-wo-header .mobile-options-button button {
        font-size: 2em;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        color: #222222;
    }

    .sh-wo-header .mobile-options {
        height: 100vh;
        width: 0%;
        background-color: rgb(255, 255, 255);
        position: fixed;
        top: 0;
        left: 0;
        overflow-x: hidden;
        display: initial;
        transition: all 0.4 linear;
        z-index: 99;
    }

    .sh-wo-header .mobile-options.collapse {
        animation: collapseOpc .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    .sh-wo-header .mobile-options.expanded {
        animation: expandOpc .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
    }

    .sh-wo-header .mobile-options .header-options {
        width: 100vw;
        display: flex;
        padding: 1.3em;
        
        top: 0;
        left: 0;
    }

    .sh-wo-header .mobile-options .header-options .logo-options {
        width: 30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .sh-wo-header .mobile-options .header-options .close-c {
        width: 70%;
        text-align: end;
    }

    .sh-wo-header .mobile-options .header-options .logo-options img {
        width: 100%;
        max-width: 100px;
    }

    .sh-wo-header .mobile-options .header-options .close-c button {
        font-size: 2em;
        margin: 0;
        padding: 0;
        border: none;
        background-color: transparent;
        color: #222222;
        font-weight: bold;
    }

    .sh-wo-header .mobile-options .figures {
        margin-top: 100%;
        position: absolute;
        right: 40px;
        bottom: 25%;
    }

    .sh-wo-header .mobile-options .options {
        margin-top: 1em;
        padding-left: 1.7em;
        padding-right: 1.7em;
        width: 100vh;
    }

    .sh-wo-header .mobile-options .options ul {
        margin: 0;
        padding: 0;
    }
    
    .sh-wo-header .mobile-options .options ul li {
        list-style: none;
        margin-bottom: 1.2em;
    }
    
    .sh-wo-header .mobile-options .options ul li a {
        font-size: 1.2em;
        color: #222222;
        font-family: 'Roboto', sans-serif !important;
    }
    
    .sh-wo-header .mobile-options .options ul li button {
        font-size: 1.2em;
        margin: 0;
        padding: 0;
        background-color: #FB245C;
        width: calc(100vw - 3.2em);
        color: #ffffff;
        border: 0 solid transparent;
        border-radius: 4px;
        padding: .5em;
        font-family: 'Roboto', sans-serif !important;
        transition: all 0.2s linear;
    }

    .sh-wo-header .mobile-options .options ul li button:active {
        background-color: #ff5884;
        border-radius: 4px;
        outline: 0 !important;
        border: none;
    }

    .sh-wo-header button:focus {
        outline: 0 !important;
    }

    @keyframes expandOpc {
        0% {
            width: 0%;
        }
        100% {
            width: 100%;
        }
    }
    
    @keyframes collapseOpc {
        0% {
            width: 100%;
        }
        100% {
            width: 0%;
        }
    }
    

}