.valor-modal {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .5);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.contenedor-valor {
    background: white;
    width: 335px;
    height: 500px;
    overflow-x: hidden;
}

.valor-header {
    height: 200px;
}

.valor-shapes {
    width: 100%;
    height: 100%;
}

.shape-one {
    position: relative;
    left: -40px;
    bottom: -75px;
}

.shape-two {
    position: relative;
    left: -130px;
    top: -50px;
}

.shape-three {
    position: relative;
    left: -60px;
    top: -37px;
}

.shape-four {
    position: relative;
    left: -20px;
    top: -35px;
}

.shape-five {
    width: 70px;
    height: 70px;
    position: relative;
    right: -290px;
    top: -85px;
}

.shape-six {
    position: relative;
    right: -230px;
    bottom: -10px;
}

.ana-profile img{
    position: relative;
    width: 100px;
    top: -130px;
    left: 35%;
}

.valor-contenido {
    padding: 10px;
    padding-top: 0;
    text-align: center;
    color: black;
}

.valor-contenido .titulo {
    font-weight: 700;
    font-size: 23px;
    line-height: 36.8px;
}

.valor-contenido .contenido {
    font-weight: 400;
    font-size: 16px;
    line-height: 28.8px;
}

.valor-contenido .btn-cerrar {
    background-color: #F64282;
    border-color: white;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    bottom: -40px;
}

.hidden {
    display: none;
}