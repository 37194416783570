#text-pymes{
    display:flex;
    margin-bottom: 20px;
    margin-top: 20px;
    display: 'flex';
    flex-direction: 'column';
    align-items: 'center';
    height: '19.619326500732065vw';
    background-size: 'auto';
    background-position: '40%';
    padding-left: '15px';
    padding-Top: '50px';
    padding-right: '15px';
}
@media screen and (max-width: 768) { 
    #text-pymes{
        margin-top: 25px;
        /* margin-bottom: 200px;
        padding-bottom: 105px; */
    }
}
