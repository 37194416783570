.coru-custom-tooltip {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: auto;
  margin-bottom: auto;
}
.coru-custom-tooltip .tooltip-text a {
  word-break: break-word;
  color: #fff;
  font-weight: bold;
  font-size: 0.9em;
}

.coru-custom-tooltip .tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(31, 31, 31) transparent transparent transparent;
}

.coru-custom-tooltip .tooltip-content:hover + .tooltip-text {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.coru-custom-tooltip .tooltip-text:hover {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 767px) {
  .coru-custom-tooltip .tooltip-text {
    width: 230px;
    visibility: hidden;
    background-color: rgb(31, 31, 31);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: calc(-215px + 1em + 4px) !important;
    opacity: 0;
    transition: opacity 0.3s;
    padding: 1em;
    transition: all 0s ease 0.2s; /*delay 1s*/
    justify-content: stretch;
  }
}
@media (min-width: 768px) {
  .coru-custom-tooltip .tooltip-text {
    width: 230px;
    visibility: hidden;
    background-color: rgb(31, 31, 31);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: calc(-115px + 1em + 4px);
    opacity: 0;
    transition: opacity 0.3s;
    padding: 1em;
    transition: all 0s ease 0.2s; /*delay 1s*/
    justify-content: stretch;
  }
}
