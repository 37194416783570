@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.modal .modal-main {
  position: fixed;
  background: white;
  width: 90%;
  max-width: 440px;
  height: auto;
  top: 50%;
  left: 50%;
  border-radius: .2em;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

@media (min-width: 767px) {
  .modal .modal-main {
    width: 70%;
  }
}