.step-info-step-list .step-container {
    display: flex;
}

.step-info-step-list .step-container .number-square {
    width: 20%;
    text-align: center;
}

.step-info-step-list .step-container .number-square .number {
    width: 60px;
    height: 60px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.step-info-step-list .step-container .number-square .number.gray {
    background-color: #CDCDCD !important;
}

.step-info-step-list .step-container .number-square .number p {
    font-size: 27px;
    font-weight: bold;
    color: #222222;
    margin-bottom: 0.25rem;
}

.step-info-step-list .step-container .number-square .line-cool {
    width: 0px;
    border: 0px;
    border-left: 2px dashed #CDCDCD;
    min-height: 70px;
    height: calc(100% - 60px - 2em);
    margin: 1em 0;
    margin-left: 29px;
}

.step-info-step-list .step-container .text-c {
    width: 80%;
}

.step-info-step-list .step-container .text-c .title {
    font-size: 1.8em;
    font-weight: bold;
    color: #222222;
}

.step-info-step-list .step-container .text-c .description {
    margin-top: .5em;
    color: #222222;
}

.step-info-step-list .button {
    width: 100%;
    margin: 2em auto;
}

.step-info-step-list .button button {
    width: 100%;
    background-color: #F64282;
    border: none;
    border-radius: 4px;
    color: #fafafa;
    font-weight: bold;
    font-size: 1rem;
    text-align: center;
    padding: .5em;
}

@media only screen and (max-width: 770px) {

    .step-info-step-list .step-container {
        padding-left: .5em;
        padding-right: .5em;
    }

    .step-info-step-list .step-container .number-square .number {
        width: 40px;
        height: 40px;
    }

    .step-info-step-list .step-container .number-square .number p {
        font-size: 18px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 0.25rem;
    }

    .step-info-step-list .step-container .number-square .line-cool {
        margin-left: 19px;
        height: calc(100% - 40px - 2em);
    }

    .step-info-step-list .step-container .text-c .title {
        font-size: 1.2em;
    }
    
    .step-info-step-list .step-container .text-c .description {
        font-size: 1.2em;
    }

}