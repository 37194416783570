.step-static-custom-data-viewer .image-info {
    max-width: 170px;
    margin: 0 auto;
    margin-bottom: 1em;
}

.step-static-custom-data-viewer .check-content {
    display: flex;
    justify-content: center;
}

.step-static-custom-data-viewer table {
    position: relative;
}

.step-static-custom-data-viewer table.info-content-table {
    margin: 0 auto;
    margin-bottom: 1em;
    color: #222222;
}

.step-static-custom-data-viewer table.info-content-table td.item-title {
    padding-right: .8em;
    text-align: right;
    font-size: 1rem;
    width: 50%;
}

.step-static-custom-data-viewer table.info-content-table td.item-value {
    font-weight: bold;
    font-size: 1rem;
    width: 50%;
}

.step-static-custom-data-viewer table button.edit-data {
    color: #F64282;
    background-color: transparent;
    border: none;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: -25px;
}

@media only screen and (max-width: 770px) {
    .step-static-custom-data-viewer .image-info {
        max-width: 120px;
    }

    .custom-step-layout .content-column table.info-content-table td.item-title {
        padding-right: .5em;
        font-size: .8rem;
    }
    
    .custom-step-layout .content-column table.info-content-table td.item-value {
        font-size: .9rem;
    }

}