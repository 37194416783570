/* estilos addPixel.js */

.tbl {
    width: 100%;
    margin: 0;
    margin-top: 20px;
    display: none
}

.tbl div {
    width: 100%
}

#tabla {
    width: 100%;
}

#tabla tr td {
    border: 1px solid #76A5AF;
    padding: 10px;
}

#tabla tr .txt-tbl {
    background: #EAD1DC;
    color: black;
    font-weight: bold;
}

.inp-tbl {
    width: 100%;
    border: none;
    outline: none;
    background: #F4F7FA;
}

#preview {
    border-radius: 3px;
    border: none;
    background: #76A5AF;
    color: white;
    padding: 10px;
}

#addPixel {
    border-radius: 3px;
    border: none;
    background: #76A5AF;
    color: white;
    padding: 10px;
}

#view {
    width: 100%;
    border-radius: 5px;
    border-width: thin;
    padding: 10px;
    display: none;
}

#gPixel {
    border-radius: 3px;
    border: none;
    background: #76A5AF;
    color: white;
    padding: 10px;
}

/* estilos shortLink.js */

.tbl-short {
    width: 100%;
    margin: 0;
    margin-top: 50px;
    display: none
}

#gShort {
    border-radius: 3px;
    border: none;
    background: #93C47D;
    color: white;
    padding: 10px;
}

.inp-short {
    display: flex;
    justify-content: space-evenly;
}

.lbl {
    color: white;
    font-weight: bold;
    padding: 15px;
    width: 9rem;
    text-align: center;
    background: #76A5AF;
    border-radius: 5px;
    margin-right: 50px;
}

.inp {
    margin-left: -65px;
    border-radius: 5px;
    border: 0.8px solid #5F6368;
    padding: 10px;
    width: 45%;
    height: 52.67px;
}

#generar {
    border-radius: 3px;
    border: none;
    background: #93C47D;
    color: white;
    padding: 10px;
    width: 140px;
}

#refresh {
    border: none;
    background: #1FC8E3;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
}

/* estilos configOTP.js */

.configOTp {
    margin: 0;
    text-align: center;
}

.titleProd {
    border-radius: 4px;
    border: none;
    width: 96.5%;
    background: #3F4D67;
    color: white;
    font-size: 18px;
    font-weight: bold;
}

.cont-config {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.btnOn-off {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 70px;
}

.btnOn-off input[type="button"] {
    width: 71.33px;
    border-radius: 50%;
    margin: 4px;
    border-style: solid;
}

.txtAmb {
    padding: 20px;
    font-weight: bold;
    border: 1px solid;
    width: 100%;
    border-radius: 5px;
}

.headConfig {
    border: 1px solid;
    padding: 10px;
    width: 100%;
    border-radius: 5px;
    background: #6AA84F;
    color: white;
    font-weight: bold;
}

.saveChanges {
    /* margin-top: 50px; */
    background: #666666;
    border-radius: 5px;
    border: none;
    padding: 10px;
    width: 200px;
    color: white;
    font-weight: bolder;
}

/* estilos Trainings.js */

.container-filterer {
    justify-content: center;
    flex-wrap: wrap;
}

.filter-element {
    flex: 22.8%;
    max-width: 22.8%;
    border: 1px solid rgb(246, 246, 246);
    margin: 10px;
    padding: 15px;
    border-radius: 15px;
    box-shadow: 6px 6px 15px -3px rgba(200, 200, 200, 0.75);
}

.filter-element img {
    width: 100%;
}

.filter-element .title {
    color: rgba(0, 0, 0, 0.87);
    text-align: center;
    padding: 15px;
}

.filter-element .description {
    color: rgb(98, 116, 125);
}

.filter-element .time {
    color: #5F6368;
}

/**/

.containerBtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.btnWhatsapp, .btnSms, .btnEmail, .btnSupMenu {
    border: none;
    background: #1FC8E3;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    min-width: 200px;
    margin: 25px;
}

.btnCreate {
    border: none;
    background: #6AA84F;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    color: white;
    max-width: 200px;
    margin: 25px;
}

.formCreate {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 35px;
    align-items: center;
}

.inputControl {
    width: 100%;
    margin-top: 25px;
}

#eventCampaign {
    width: 100%;
}

#templatesCampaign {
    width: 40%;
}

.controlInput {
    display: flex;
    justify-content: space-around;
}

.labelText {
    display: flex;
    font-size: 18px;
    text-align: center;
    align-items: center;
    width: 100%;
}

.labelText span {
    margin-right: 15px;
}

.containerWork {
    max-width: 100%;
    height: 80vh;
    border: 1px solid #000;
    border-radius: 5px;
    overflow-y: scroll;
}

#newCamp {
    max-width: 100%;
    height: 100%;
    border: 1px solid #000;
    border-radius: 5px;
}

/*imageUpload*/

#img-upload {
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin: 40px;
}

#imageUP {
    height: 50px;
}

#div-upload {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.content-folders {
    display: flex;
    justify-content: space-around;
    margin: 20px;
}

.btn-folders {
    width: 10rem;
    border: none;
    height: 2.5rem;
}

#uploadImages {
    width: 50%;
}

/*Dashboardsnc*/

.content-dash {
    margin: 50px auto;
    margin-top: 0;
    text-align: center;
}

.config-seg {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
}

.column-1-results {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.column-2-results {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: justify;
}

.config-seg-results {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    text-align: justify;
}

.lbl-results {
    height: 100px;
    width: 100px;
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    color: white;
}

.config-seg-perc {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/*blacklist*/

#addNumberOrMail {
    background: #3F4D67;
    color: white;
    width: 30%;
    font-size: 20px;
    border-style: none;
    padding: 5px;
    margin-top: 20px;
    border-radius: 5px;
}

#deleteNumberOrMail {
    background: #3F4D67;
    color: white;
    width: 30%;
    font-size: 20px;
    border-style: none;
    padding: 5px;
    margin-top: 20px;
    border-radius: 5px;
    margin-left: 20px;
}

#updateTableBlacklist {
    width: 20%;
    border-radius: 5px;
    font-size: 15px;
    border-style: none;
    margin-top: 20px;
    padding: 1px;
}

/**/

#aut-sp:hover {
    background-color: #EEEEEE;
    cursor: pointer;
    width: 100%;
}

#aut-sp {
    display: flex;
    justify-content: right;
}

#autoUserDetail {
    width: 100%;
    height: 45px;
}

#cont-aut {
    width: 100%;
    padding: 0;
}

.content-autocomplete-userInfo {
    margin: 20px;
    display: flex;
    justify-content: center;
}

.autoContainer {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 400px;
    border: 0.5px solid gray;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 10px;
}
.nav-item.nav-link:hover {
    color:blue
}
/* .MuiInput-formControl{
    margin-top: 30px !important;
} */
.MuiInputLabel-formControl{
    margin-top: -10px;
}

.titleCampaing {
    color: #000000;
    font-size: 18px;
    margin: auto;
    margin-bottom: 30px;
    border: solid 1px #3F4D67;
    border-radius: 4px;
    width: 30%;
    padding: 5px;
    background-color: #e5e5e5;
}

.detailsCampaing {
    background-color: #e5e5e5;
    border: solid 1px #3F4D67;
    border-radius: 4px;
    font-size: 14px;
    margin-left: 25px 20px;
    width: 55%;
    padding: 7px;
}

.txtCampaingLabel {
    display: flex;
    margin: 20px; 
    font-size: 14px;
    color: #000000;
}

.tamCampaingFlex {
    width: 50%;
}

.tamCampaingFlexTam {
    display: flex;
}

@media (max-width: 760px) {
    .tamCampaingFlex {
        width: 100%;
    }
    .tamCampaingFlexTam {
        display: block;
    }
    .titleCampaing {
        width: 45%;
    } 
    .MuiInputLabel-formControl{
        margin-top: -7px;
    }
}