
.formDigit
{
	margin-top: 10%;
	margin-bottom: 0%;
}

.width100
{
	width: 100%;
}


.MuiInput-underline.Mui-error:after
{
	/*background: url(/static/img/verified2.png) 96% 50% no-repeat rgb(255, 255, 255);*/
}

.MuiInput-underline:after
{
	border-bottom: 1px solid #DBDBDB!important;
	font-family: 'DM Sans'!important;
}

.MuiInput-underline-error:after
{
	border-bottom: 2px solid red!important;
	font-family: 'DM Sans'!important;
}

.formheight
{
	height: 645px;
}

.link
{
	color: #252525!important;
	cursor:pointer!important;
}

.link:hover
{
	font-weight: bold;
	font-size: 15px;
}

.rosa
{
	color: #d31e4d!important;
}


.Uppercase
{
	text-transform: uppercase;
}

.avatarSelect
{
	width: 70px!important;
}

.avatar:hover
{
	width: 55px;
}

.avatar{
	cursor: pointer;
	width: 50px;
}

.modalAvatar
{
	width: 50%;
    padding: 16px 32px 24px;
    position: absolute;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
}

@media (max-width: 600px)
{
	.modalAvatar{
		width: 100%!important;
	}
}

.noDisplay
{
	display: none;
}

.Novisible{
	display: none!important;
}

.padding16
{
	padding: 16px;
}

.typeul
{
	list-style-type: lower-latin;
}

.typeList
{
	list-style-type: decimal;
}

.pink
{
	color: #fb245c;
}

.ol{
	font-family: 'DM Sans',sans-serif !important;
    text-align: left;
    font-size: 18px;
    color: #000;
}

.linklist:hover{
	color: #d31e4d;
}

.floatR
{
	float: right;
	width: 50%;
}

.floarL
{
	float: left;
	width: 50%;
}

.marginTop2
{
	margin-top: 2%!important;
}

.borderCuad
{
	border: 1px solid #A6A6A6; 
	margin:5px;
	border-radius: 5px;
	min-height: 116px;
	display: flex;
}

.center{
	margin:auto;
}

.fuente
{
	font-size: 10px;
	text-align: left;
	display: block;
    margin-left: 3%;
}

@media (min-width: 700px)
{
	.borderCuad
	{
		border: 1px solid #A6A6A6; 
		margin:5px;
		border-radius: 5px;
		height: 120px;
	}
}