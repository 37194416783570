@import url("https://fonts.googleapis.com/css?family=Montserrat");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css?family=DM+Sans&display=swap&css");
@import url("https://fonts.googleapis.com/css2?family=Cabin&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.cabin {
  font-family: "Cabin Regular", arial !important;
  font-style: normal;
}

.roboto {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
}

.helvetica {
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  font-style: normal;
}

.sans-pro {
  font-family: "Source Sans Pro", sans-serif !important;
}

.monserrat {
  font-family: "Montserrat" !important;
}

.dm-sans {
  font-family: "DM Sans", sans-serif !important;
}

a:hover {
  color: #fff;
}

body {
  background: #fafafa !important;
}

.cursor-p {
  cursor: pointer;
}
.colortxt {
  color: #000000 !important;
}
.selectParent select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 2px 2px 2px;
  background: #ffffff
    url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat right !important;
  background-position: 98% center !important;
  margin-top: 10px !important;
  height: 56px !important;
  border-radius: 4px;
}
.selectParentAddress select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 2px 2px 20px;
  background: #ffffff
    url("data:image/svg+xml;utf8,<svg fill='black' height='34' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
    no-repeat right !important;
  background-position: 98% center !important;
  margin-top: 10px !important;
  height: 56px !important;
  border-radius: 4px;
}
.iconEdit {
  width: 15px !important;
  margin-left: 15% !important;
  margin-top: 20px !important;
}

.iconEdit1 {
  position: absolute;
  left: 25%;
  /* top: 30%; */
  cursor: pointer;
  width: 15px !important;
  margin-left: 25.75% !important;
  margin-top: 0.5%;
  cursor: pointer;
  /* 
   position: relative;
   left: 25%;
   top: -10%;
   cursor: pointer;
   width: 15px !important;
   margin-left: 27% !important;
   margin-top: -15%;
   cursor: pointer; */
  /* 
   width: 15px !important;
   margin-left: 15% !important;
   margin-top: 20px !important; */
  /*display: none;*/
}

.iconEditCommon {
  cursor: pointer;
  width: 15px !important;
  margin-left: -5% !important;
  margin-top: 20px !important;
}

.iconScore {
  cursor: default;
  width: 22px !important;
  margin-left: 288px !important;
  margin-top: 20px !important;
}

.fs-16 {
  font-size: 16px !important;
}
.rotate180 {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.imageweb {
  display: block;
}

.imagemob {
  display: none;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.border-pink-active {
  border-style: solid;
  border-width: 1px;
  border-color: #f64282;
}

.option-mvp {
  padding-top: 6%;
  cursor: pointer;
}

.option-mvp:hover {
  border-style: solid;
  border-width: 1px;
  border-color: #6bd5af;
  background-color: #6bd5af;
  color: #fff;
}

.txt-large {
  padding-top: 2% !important;
}

.txt-large:hover {
  padding-top: 2% !important;
}

.option-sel {
  padding-top: 6%;
  border-style: solid;
  border-width: 1px;
  border-color: #6bd5af;
  background-color: #6bd5af;
  color: #fff;
  margin: auto;
}

.beneficios:active {
  border-style: solid;
  border-width: 1px;
  border-color: #f64282;
}

.pl-color {
  background-color: #68cdc7 !important;
}

.first-color {
  background-color: #f5d495 !important;
}

.confetti {
  background-image: url("../img/anacoach/confeti1.png");
}

.MuiPaper-rounded {
  border-radius: 8px !important;
}

.MuiInputLabel-shrink {
  font-size: 16px !important;
  letter-spacing: -0.0002em !important;
}

.MuiInputBase-root {
  height: 60px !important;
  margin-top: 10px;
  background: #fff;
}

.MuiTextField-root.registro > .MuiInputBase-root {
  background: transparent !important;
}

.MuiFormLabel-root {
  line-height: 2.5 !important;
}

.MuiSelect-select.MuiSelect-select {
  margin-top: 18.5px;
}

.MuiInputLabel-outlined.MuiInputLabel-marginDense {
  padding-top: 2px;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  padding-top: 0;
  transform: translate(14px, 5px) scale(0.75) !important;
}

.MuiInputLabel-outlined {
  transform: translate(14px, 30px) scale(1) !important;
}

.MuiSelect-select:focus {
  background-color: transparent !important;
}

.trails-text {
  color: #07210a;
  font-size: 3vw;
  font-weight: bold;
  height: 100%;
  letter-spacing: 0.5px;
  margin-left: 0;
  overflow: visible;
  position: relative;
  text-align: left;
  will-change: transform, opacity;
  width: 100%;
  /* margin-top: 2vw; */
}

.trails-text > div {
  overflow: visible;
}

.centrar {
  text-align: center !important;
}

.col-md-4 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.triangulo-equilatero-bottom {
  width: 0;
  height: 0;
  border-right: 18px solid transparent;
  border-top: 18px solid transparent;
  border-left: 18px solid transparent;
  border-bottom: 18px solid #fff;
  position: absolute;
  margin-top: -56px;
  margin-left: 125px;
}

/*Animación 2*/

.draggable {
  margin: auto;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: url("https://uploads.codesandbox.io/uploads/user/b3e56831-8b98-4fee-b941-0e27f39883ab/Ad1_-cursor.png")
      39 39,
    auto;
}

.draggable-main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  position: relative;
  width: 300px;
  height: 240px;
}

.content > div {
  position: absolute;
  width: 288px;
  height: 90px;
  overflow: visible;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: white;
  line-height: 18px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 18px;
  letter-spacing: 1px;
  padding-top: 25px;
}

.text-session {
  color: black !important;
}

.paper-styled {
  padding: 12px;
  min-width: 295px;
  min-height: 360px;
}

.paper-styled-ent {
  padding: 0;
  min-width: 295px;
  min-height: 405px;
  cursor: pointer;
}

.paper-styled-ent-medium {
  padding: 0;
  min-width: 207.87px;
  min-height: 360.15px;
  /*417.15px*/
  cursor: pointer;
}

.paper-styled-ent-feed {
  padding: 0;
  min-width: 207.87px;
  min-height: 360.15px;
  /*417.15px*/
}
.web {
  display: flex !important;
}
.mob {
  display: none !important;
}
.d-web {
  display: list-item;
}

.d-mob {
  display: none;
}

.d-block {
  display: inline-block;
}

/*Burguer menu*/

.view {
  width: 100% !important;
}

.no-view {
  width: 0 !important;
}

.sidepanel div {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
}

.sidepanel div:hover {
  color: #f1f1f1;
}

.sidepanel .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

.closeDiv {
  font-size: 36px;
  position: relative;
  top: -1vw;
  left: -2vw;
}

.styleBenef {
  color: #4f4f4f;
  font-size: 14px;
}

.listImagen {
  list-style-image: url("../img/anacoach/StartList.svg");
}

/*End burguer menu*/

.cuadro-externo {
  display: none;
}

.input-coru {
  /*height: 60px !important;*/
  margin-top: 0px !important;
  margin-bottom: 20px;
}

.input-coru:focus {
  height: 70px !important;
}

.inputs-bottom {
  /*margin-bottom: 6px !important;
  margin-top: 10px !important;*/
  border: none !important;
  width: 100% !important;
  margin: 0 20px;
}

.inputs-bottom-fn {
  margin-bottom: 0px !important;
  margin-top: 0 !important;
  border: none !important;
  width: 47% !important;
}

.ProductExtraIncome {
  border-radius: 4px;
  width: 320px;
}

.marginImgHeader {
  height: 340px;
  margin-top: 70px;
  margin-bottom: auto;
  display: flex;
  align-content: center;
}

.adress-input {
  /*border-top: none !important;
    border-left: none !important;
    border-right: none !important;*/
  color: #000 !important;
  outline: none !important;
  background: #ffffff !important;
  border-radius: 4px !important;
  margin-top: 10px !important;
  height: 56px !important;
}

.adress-input:-webkit-autofill {
  background-color: #ffffff !important;
}

.adress-input:focus {
  background-color: #ffffff !important;
}

.adress-input:hover {
  background-color: #ffffff !important;
}

.adress-select {
  appearance: none;
}

.adress-select i {
  content: "\025be";
  display: table-cell;
  text-align: center;
  padding-top: 7px;
  width: 30px;
  height: 30px;
  background-color: #d9d9d9;
  position: absolute;
  top: 0;
  right: 0px;
  pointer-events: none;
}

.form-control:focus {
  color: #000;
  background-color: #fff;
  border-color: none;
  outline: none;
  box-shadow: none;
}

.municipio {
  display: none !important;
}

.estado {
  display: none !important;
}

.paddle1 {
  background-color: transparent;
  background-image: url("../img/anacoach/regreso.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 80%;
  bottom: 0;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  outline: none;
  border: none;
}

.paddle2 {
  background-color: transparent;
  background-image: url("../img/anacoach/proximo.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 80%;
  bottom: "";
  width: 28px;
  height: 28px;
  border-radius: 14px;
  outline: none;
  border: none;
}

.paddle1ft {
  background-color: transparent;
  background-image: url("../img/anacoach/regreso.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 67%;
  bottom: 0;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  outline: none;
  border: none;
}

.paddle2ft {
  background-color: transparent;
  background-image: url("../img/anacoach/proximo.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 67%;
  bottom: "";
  width: 28px;
  height: 28px;
  border-radius: 14px;
  outline: none;
  border: none;
}

.paddle1cc {
  background-color: transparent;
  background-image: url("../img/anacoach/regreso.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 72%;
  bottom: 0;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  outline: none;
  border: none;
}
.paddle1FastTrack {
  margin-top: 36%;
  margin-left: -10%;
  background-color: transparent;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAArQAAAK0BVE7WMAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACxSURBVEiJY/j//z8DJbhYVsJUh5fzU7GcnBY2eYoNl+Ng+xUoLngAlxqaGk62BSUK4uayRBhOlgWkGE6yBaQaTpIFZQriFtIcbL9JMZxoC8g1nCgLiuSlrMg1nKAFRfJSVtLs5BuO1wJqGI7TggJ5eUlqGP7//38GJgZagwEJIrpEMl2SKQzTNKNRaglJrqFpYUeuJWSlDJpWODBM0yqTFEsosgBmiQonx/dSJRlVbPIAP8Htk/Dg79QAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  position: inherit;
  width: 28px;
  height: 28px;
  border-radius: 14px;
  outline: none;
  border: none;
  display: flex;
  padding-right: 5%;
}
.paddle2FastTrack {
  margin-top: 36%;
  background-color: transparent;
  background-image: url("../img/anacoach/proximo.png");
  background-repeat: no-repeat;
  position: inherit;
  width: 28px;
  height: 28px;
  margin-left: 2%;
  border-radius: 14px;
  outline: none;
  border: none;
  display: flex;
}

.paddle2cc {
  background-color: transparent;
  background-image: url("../img/anacoach/proximo.png");
  background-repeat: no-repeat;
  position: absolute;
  top: 72%;
  bottom: "";
  width: 28px;
  height: 28px;
  border-radius: 14px;
  outline: none;
  border: none;
}

.paddlePL {
  top: 78% !important;
}

.paddleDebts {
  top: 67% !important;
}

.paddle1:hover {
  background-image: url("../img/anacoach/regreso.png");
  background-color: transparent;
  background-repeat: no-repeat;
}
.paddle1FastTrack:hover {
  background-image: url("../img/anacoach/regreso.png");
  background-color: transparent;
  background-repeat: no-repeat;
}
.paddle2FastTrack:hover {
  background-image: url("../img/anacoach/proximo.png");
  background-color: transparent;
  background-repeat: no-repeat;
}

.paddle2:hover {
  background-image: url("../img/anacoach/proximo.png");
  background-color: transparent;
  background-repeat: no-repeat;
}

.paddle2cc:hover {
  background-image: url("../img/anacoach/proximo.png");
  background-color: transparent;
  background-repeat: no-repeat;
}
.paddle1ft:hover {
  background-image: url("../img/anacoach/regreso.png");
  background-color: transparent;
  background-repeat: no-repeat;
}

.paddle2ft:hover {
  background-image: url("../img/anacoach/proximo.png");
  background-color: transparent;
  background-repeat: no-repeat;
}
.list-none {
  list-style: none;
}
.lefty {
  left: 12%;
}

.righty {
  right: 12%;
}

.index-btns {
  z-index: 1;
}

.tablePresupuesto {
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
  padding-top: 4%;
  padding-left: 5%;
  padding-right: 5%;
}

.selectedBen {
  border-style: solid;
  border-width: 1px;
  border-color: #ec407a !important;
}

.important {
  font-weight: 600;
  color: #ec407a;
}

.carousel-one-item {
  display: flex;
  justify-content: center;
}

.icon-messenger {
  transition: filter 0.3s ease;
}

.icon-messenger:hover {
  filter: brightness(70%);
  cursor: pointer;
}

.icon-messenger:active {
  filter: brightness(100%);
}

.icon-facebook {
  transition: filter 0.3s ease;
}

.icon-facebook:hover {
  filter: brightness(70%);
  cursor: pointer;
}

.icon-facebook:active {
  filter: brightness(100%);
}

.icon-whatsapp {
  transition: filter 0.3s ease;
}

.icon-whatsapp:hover {
  filter: brightness(70%);
  cursor: pointer;
}

.icon-whatsapp:active {
  filter: brightness(100%);
}

.icon-copy {
  transition: filter 0.3s ease;
}

.icon-copy:hover {
  filter: brightness(70%);
  cursor: pointer;
}

.icon-whatsapp:active {
  filter: brightness(100%);
}

.d-mobile {
  display: none;
}

.home-carousel .home-carousel-item .home-carousel-img {
  object-fit: cover;
}

.home-header-mob {
  position: fixed;
  left: 0;
  width: 100%;
  padding: 0rem 10%;
  background-color: #fafafa;
  top: 0;
  z-index: 2;
}

.paper-styled-ent-smoll {
  min-height: 375.98px;
  min-width: 282px;
  max-width: 282px;
}

.center {
  text-align: center;
}

.errorPassword {
  border: 1px solid red !important;
}

.marginAuto {
  margin: 0 auto !important;
}

.marginAutoCC {
  margin: 0 0 20px 0 !important;
}

.counstColCC {
  display: flex;
  flex-wrap: wrap;
  flex-basis: auto;
  justify-content: space-between;
}

.counstColCC1 {
  display: flex;
  flex-wrap: wrap;
  flex-basis: auto;
  justify-content: center;
}

.zoomImagen:hover {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}

.zoomImagen {
  overflow: hidden;
}

.widthsection {
  width: 134% !important;
}

.display-web {
  display: block;
}

.display-mobile,
.display-mobile-flex {
  display: none;
}

.FeedSectionDisplayHidden {
  display: none;
}

.success-button {
  background-color: #fb245c;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  height: auto;
  outline: none;
  padding: 9px 15px;
  text-align: center;
  margin-left: 0;
  margin-right: auto;
  max-width: 50%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.success-button:hover {
  background-color: #d31e4d;
  cursor: pointer;
}

.pl-preferred-credit-card label {
  max-width: 100%;
}

.modalShare {
  width: 50%;
  padding: 16px 32px 24px;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  border-radius: 5px;
  background-color: #fafafa;
}

.CarruselContainer {
  position: absolute;
  width: 484.74px !important;
  height: 788.74px !important;
  left: -13px !important;
  top: 105px !important;
}

.rec-slider-container {
  margin: 0px !important;
}

/* .rec-arrow-left,
.rec-arrow-right {
  display: none !important;
} */

.rec-arrow-up,
.rec-arrow-down {
  display: none !important;
}

/* .rec-pagination {
  margin-top: -590px !important;
  margin-bottom: 490px !important;
  z-index: 1;
} */
/* #CarruselMetasLogros > .rec-pagination {
  margin-top: -540px !important;
  margin-bottom: 0 !important;
  z-index: 1;
} */

.CarruselControl > .rec-pagination {
  display: none !important;
}

.CarruselControl > .rec-carousel {
  background: #fff;
}

.CarruselControl > div > .rec-arrow-left {
  display: block !important;
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: 0 0 0px 0px #333;
}

.CarruselControl > div > .rec-arrow-right {
  display: block !important;
  background-color: transparent !important;
  border: 0px !important;
  box-shadow: 0 0 0px 0px #333;
}

.CaruselC > .rec-pagination {
  margin-top: -447px !important;
  margin-bottom: 389px !important;
  z-index: 1;
}

.rec.rec-dot.rec-dot_active {
  cursor: pointer;
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.5) !important;
  background-color: #07210a !important;
}

.rec-slider > div {
  position: relative;
}

.rec-slider {
  min-height: auto !important;
}

.wo-input {
  display: flex;
  flex-direction: column;
}

.rec.rec-dot {
  height: 10px !important;
  width: 10px !important;
  padding: 2px !important;
  background-color: rgb(229, 229, 229) !important;
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.5) !important;
}

.sc-ptSuy {
  /*background-color: rgb(229, 229, 229);*/
  box-shadow: 0 0 0px 0px rgba(0, 0, 0, 0.5);
}

.rec-carousel-wrapper {
  background: #fafafa;
}

.sugerenciaBlack {
  color: black;
}

.sugerenciaGreen {
  color: #28a745;
}

.paddingLaterales {
  padding-right: 15px;
  padding-left: 15px;
}

.MuiInputBase-root.busqueda {
  height: auto !important;
  margin-top: 0px !important;
}

.MuiInputBase-root.busqueda > input {
  font-size: 1.1vw !important;
}
.cursorPointer {
  cursor: pointer;
}

.enlace {
  text-decoration: underline;
  font-weight: bold;
  font-style: italic;
  color: blue;
}

#CarruselEmpresasContent > .rec-carousel-wrapper > .rec-carousel,
#CarruselEmpresasContentHome > .rec-carousel-wrapper > .rec-carousel {
  margin: auto !important;
}

#CarruselEmpresasContent > .rec-carousel-wrapper > .rec-pagination,
#CarruselEmpresasContentHome > .rec-carousel-wrapper > .rec-pagination {
  margin-top: -200px !important;
  margin-bottom: 0% !important;
  z-index: 0;
}
#CarruselEmpresasContent > .rec-carousel-wrapper > .rec-carousel,
#CarruselEmpresasContentLogros > .rec-carousel-wrapper > .rec-carousel {
  margin: auto !important;
}

#CarruselEmpresasContent > .rec-carousel-wrapper > .rec-pagination,
#CarruselEmpresasContentLogros > .rec-carousel-wrapper > .rec-pagination {
  margin-top: -200px !important;
  margin-bottom: 0% !important;
  z-index: 0;
}

.video {
  margin: auto;
  z-index: 5;
  margin-left: 5%;
}

.videoHome {
  margin-left: -13%;
  margin-top: 17%;
}

.Play:hover {
  opacity: 1 !important;
}

.Play {
  margin-left: 62%;
  margin-top: 275px;
}
.MuiOutlinedInput-root {
  margin-bottom: 20px;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #f64282 !important;
}

.tablagen {
  display: table;
}
.fila {
  display: table-row;
}
.col {
  display: table-cell;
  padding: 1px;
  line-height: 300px;
  vertical-align: middle;
  line-height: normal;
}

.Scrol {
  overflow: auto;
  height: 600px;
}

.wc-button.wc-button-regular {
  display: none !important;
}

.Novisible {
  display: none;
}

#AsesorDiv:hover {
  background-color: #fff !important;
  border: 1px solid #fb245c;
  min-height: 66px;
  width: 160px;
  border-radius: 10%;
  bottom: 65px;
  right: 30px;
  margin-bottom: 20px;
  position: fixed;
  z-index: 3000;
  box-shadow: 0px 0px 5px 2px #eeeeee;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px;
}
#AsesorDiv {
  /*-webkit-transition: width 0.5s, border-radius 1s;
   -moz-transition: width 0.5s, border-radius 1s;
   -o-transition: width 0.5s, border-radius 1s;
   transition: width 0.5s, border-radius 1s;
   -webkit-transition-timing-function: linear;*/
  background: transparent;
}
#AsesorDiv:hover > .subtext {
  display: block !important;
}

.subtext {
  display: none;
}

.btn-Asesor:hover {
  background: #d31e4d !important;
  border-color: #d31e4d !important;
  color: #ffff;
}

.btn-Asesor:hover > div > h3 > b {
  color: #ffff !important;
}

.mensaje {
  background: url(../img/home/chat/chatW.svg);
  background-position: center;
  background-attachment: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
.mensaje2 {
  background: url(../img/home/chat/MensajeTwo.png);
  background-position: center;
  background-attachment: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.what {
  background: url(../img/home/chat/WhatB.svg);
  background-position: center;
  background-attachment: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.btn-Asesor:hover > div > .what {
  background: url(../img/home/chat/WhatW.svg);
  background-position: center;
  background-attachment: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.btn-Asesor:hover > div > .mensaje2 {
  background: url(../img/home/chat/chatW.svg);
  background-position: center;
  background-attachment: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
.startTraining {
  text-align: center;
  width: 100%;
}
#lidHipoteca {
  margin-left: 1vw;
}
.divcardbtn {
  padding-left: 0.5vw;
  padding-right: 1vw;
  background-color: rgba(0, 0, 0, 0.12);
}
.container-bar {
  /* white-space: nowrap; */
  white-space: pre-line;
  overflow: initial;
  text-overflow: ellipsis;
}
.container-bar-static {
  white-space: nowrap;
  overflow: initial;
  text-overflow: ellipsis;
}
.button_downWidgets {
  margin-top: 10%;
}
.margin_10 {
  margin-top: 5vw;
}
.iconUser {
  width: 3vw;
  border: 1px solid #a6a6a6;
  object-fit: cover;
  object-position: top;
  height: 3vw;
}
.containerAnaSucces {
  position: absolute;
  top: 30%;
  left: 0%;
  text-align: center;
  z-index: 1;
}

.imgAnaSucces {
  padding: 0.2%;
  border: 2px solid #61d2fa;
  border-radius: 45px 45px 45px 45px;
  position: absolute;
  width: 100px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1;
}
.ContainerResp {
  margin-left: 24%;
  margin-right: 24%;
}
.alingCenter {
  text-align: center;
}
.topSuccess {
  margin-top: 8px;
}

.pru {
  padding-left: 6.5%;
}
.prucolo {
  background-color: lawngreen;
}

.falseAna {
  display: none;
}
.trueAna {
  display: block;
}
.boxbutton {
  display: flex;
  width: 100%;
  align-items: center;
}
.MuiOutlinedInput-input {
  margin-bottom: 20px;
}

.charSimulator {
  width: 100%;
  height: 27.5px;
}
.tabletr {
  height: 51px;
  background-color: #f0d094;
}
.tabletrtitle {
  width: 100%;
  height: 51px;
  background-color: #f0d094;
}

.tableTotal {
  font-weight: bold;
}
.tabletd {
  height: 58px;
}
.tablew {
  width: 50%;
  align-items: center;
}
.alignItemCenter {
  display: flex;
  flex-direction: column;
  padding: 0px;
  align-items: center;
  border-style: solid 1px;
  background: #ffffff;
  box-shadow: 0px 4px 18px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.disabledAhorro {
  color: #fff !important;
  background-color: #aaa !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: #aaa !important;
  cursor: auto !important;
}

.contentMostrarmas {
  position: relative;
  left: 0;
  margin-left: -100%;
  top: 100px;
  background-color: transparent;
  margin-top: 0px;
  overflow-y: auto;
}

.marginUserBorder {
  margin: 35px 0 35px 4%;
  max-height: 83px;
}

.borderFilter {
  border-color: #a370ff !important;
  background: #a370ff !important;
}
.itemAhorro {
  padding: 3px !important;
  vertical-align: initial !important;
}
/*Media Min*/
.mobcub {
  margin-top: -26px !important;
}
.mobcubQ {
  margin-top: 3px !important;
  margin-right: 10px;
}
.paddleft {
  padding-left: 0 !important;
}
.topAnaModal {
  margin-top: -18px;
}
.rewards {
  border: 1px solid #f64282;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 125px;
  padding: 8px;
  height: 90px;
}
@media (min-width: 2970px) {
  .marginComentLg {
    width: auto;
    margin-left: 38%;
    margin-right: 38%;
  }
  .iconEdit1 {
    margin-left: 25.5% !important;
  }
  /* .marginImgHeader {
      margin-top: 2vw;
   } */
}
@media (min-width: 2561px) {
  .iconEdit {
    margin-left: 14% !important;
  }
  .iconEditCommon {
    margin-left: 0 !important;
  }
}
@media (min-width: 2255px) {
  .marginImgHeader {
    height: 250px;
    margin-top: 100px;
  }
}
@media (min-width: 2080px) {
  .iconEdit {
    margin-left: 15% !important;
  }
  .iconEditCommon {
    margin-left: 0 !important;
  }
  /* .marginImgHeader {
      height: 250px;
      margin-top: 90px;
  }  */
}
@media (min-width: 1870px) {
  .iconEdit {
    margin-left: 18% !important;
  }
  .iconEditCommon {
    margin-left: -1% !important;
  }
  .imgTitle2200 {
    width: 300px;
  }
}
@media (min-width: 1800px) {
  /*  .marginImgHeader {
      margin-top: 3vw;
   } */
}
@media (min-width: 1700px) {
  .iconEdit {
    margin-left: 19% !important;
  }
  .iconEditCommon {
    margin-left: -2% !important;
  }
  /* .marginImgHeader {
      margin-top: 5vw;
   } */
}
@media (min-width: 1600px) {
  .iconUser {
    width: 2vw;
    border: 1px solid #a6a6a6;
    object-fit: cover;
    object-position: top;
    height: 2vw;
  }
}

@media (min-width: 1500px) {
  /* .marginImgHeader {
      margin-top: 8vw;
   } */
  .iconEdit {
    margin-left: 22% !important;
  }
  .iconEditCommon {
    margin-left: -3% !important;
  }
}
@media (min-width: 1280px) {
  /*   .marginImgHeader {
      margin-top: 6vw;
   } */
}
@media (min-width: 1180px) {
  .marginComentLgSuccess {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
  .marginComentLgSuccess1 {
    max-width: 1200px;
    margin-left: 20%;
    margin-right: 20%;
  }
  .imgTitle2200 {
    width: 300px;
  }
}

@media (min-width: 1200px) {
  .marginComentLgSuccess1 {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
  }

  .marginComentLgSuccess {
    max-width: 1900px;
    margin-left: auto;
    margin-right: auto;
  }
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1200;
  }
  .iconEdit {
    margin-left: 25% !important;
  }
}
@media (min-width: 1000px) {
  .marginComentLg {
    width: 700px;
  }
  .marginComentLgSuccess1 {
    width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 769px) {
  .ExtraIncomeMob {
    height: 335px;
  }
  .ProductExtraIncome {
    border-radius: 4px;
    width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-bottom: 18.5px !important;
  }
  .frameWeb {
    width: 100%;
    height: 330px;
  }
  .closeDiv {
    margin-top: -30px;
    margin-left: 615px;
  }
  .form-web {
    width: 670px;
    margin-left: auto;
    margin-right: auto;
  }
  .shapes {
    background-image: url("../img/anacoach/Shapes.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: inherit;
  }
  .shapes2 {
    background-image: url("../img/anacoach/Shapes512.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .triangulo-equilatero-bottom {
    margin-top: -75px;
    margin-left: 270px;
  }
  .content {
    width: 100%;
    height: auto !important;
  }
  .content > div {
    position: relative;
    height: 120px;
    font-size: 18px;
    width: 320px;
    text-align: center;
    margin: 10px;
  }
  .content > div:nth-child(1) {
    transform: none !important;
    padding-top: 45px;
  }
  .content > div:nth-child(2) {
    transform: none !important;
    padding-top: 45px;
  }
  .content > div:nth-child(3) {
    transform: none !important;
    padding-top: 45px;
  }
  .content > div:nth-child(4) {
    transform: none !important;
  }
  .paper-styled {
    min-height: auto;
    padding: 10px;
    min-width: 250px;
    width: 284px;
  }
  .m-auto {
    margin-left: 0 !important;
  }
  .paper-styled-ent {
    min-height: 405px;
  }
  .paper-styled-ent-feed {
    min-height: 417.15px;
  }
  .paper-styled-ent-medium {
    min-height: 417.15px;
    min-width: 207.87px;
    max-width: 207.87px;
  }
  .paper-styled-ent-smoll {
    min-height: 375.98px;
    min-width: 282px;
    max-width: 282px;
  }
  .col-web {
    display: none;
  }
  .col-web-tam {
    min-width: 100%;
  }
  .trails-text > div {
    height: 100% !important;
    margin-left: 6.5%;
  }
  .mvp-landing > div {
    margin-left: 0 !important;
  }
  .confetti {
    background-image: url("../img/anacoach/Shapes.png");
  }
  .marginUserBorder {
    margin: 25px 0 25px 4%;
  }
}
@media (min-width: 750px) {
  #CarruselEmpresasContent
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-arrow-left,
  #CarruselEmpresasContent
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-arrow-right {
    display: block !important;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: 0 0 0px 0px #333;
  }

  #CarruselEmpresasContentHome
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-arrow-left,
  #CarruselEmpresasContentHome
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-arrow-right {
    display: block !important;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: 0 0 0px 0px #333;
    margin-top: -50px;
  }

  #CarruselEmpresasContentLogros
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-arrow-left,
  #CarruselEmpresasContentLogros
    > .rec-carousel-wrapper
    > .rec-carousel
    > .rec-arrow-right {
    display: none !important;
    background-color: transparent !important;
    border: 0px !important;
    box-shadow: 0 0 0px 0px #333;
    margin-top: -50px;
  }

  .fQSEmU:hover:enabled,
  .fQSEmU:focus:enabled {
    color: #07210a !important;
    background-color: transparent;
    box-shadow: 0 0 2px 0 transparent !important;
  }

  .rec-carousel > button:focus {
    outline: 5px auto transparent !important;
  }

  .rec-carousel > button:hover,
  .rec-carousel > .hoverbtn:hover {
    background: #d31e4d !important;
    border-color: #d31e4d !important;
    color: #ffff;
  }
}

@media (min-width: 700px) {
  .video {
    display: none;
  }
  .closeDiv {
    top: -0vw;
  }
}
/* Media Min and Max */
@media (max-width: 1999px) and (min-width: 1500px) {
  .iconEdit {
    cursor: pointer;
    width: 15px !important;
    margin-left: 14% !important;
    margin-top: 20px !important;
    /*display: none;*/
  }
  /* .marginImgHeader {
      height: 340px;
      margin-top: 70px;
   } */
}
@media (max-width: 3000px) and (min-width: 2000px) {
  .iconEdit {
    cursor: pointer;
    width: 15px !important;
    margin-left: 14% !important;
    margin-top: 20px !important;
    /*display: none;*/
  }
}

@media (max-width: 1024px) and (min-width: 768px) {
  .iconEdit {
    cursor: pointer;
    width: 15px !important;
    margin-left: 45% !important;
    margin-top: 20px !important;
    /*display: none;*/
  }
}
@media (min-width: 867px) and (max-width: 999px) {
  .iconEdit1 {
    position: absolute;
    left: 25%;
    cursor: pointer;
    width: 15px !important;
    margin-left: 37% !important;
  }
}
@media (min-width: 768px) and (max-width: 769px) {
  .marginComentLgSuccess1 {
    max-width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media (max-width: 767px) and (min-width: 768px) {
  .marginTime {
    margin-left: 50px;
  }
}

/*Media Max*/
@media (max-width: 1827px) {
  .trails-text {
    height: 100%;
    line-height: 150%;
    text-align: left;
  }
}

@media (max-width: 1187px) {
  .marginTime {
    margin-left: 1vw;
  }
}
@media (max-width: 1000px) {
  /* .marginImgHeader {
      margin-top: 17vw;
   } */
  /* .iconEdit1 {
      margin-left: 40% !important;
   }
  */
}

@media (max-width: 866px) {
  .iconEdit1 {
    margin-left: 20% !important;
  }
}

@media (max-width: 768px) {
  .counstColCC {
    column-count: 1;
  }
  .counstColCC1 {
    column-count: 1;
  }
  .ContainerResp {
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .paddle1FastTrack {
    display: none;
  }
  .paddle2FastTrack {
    display: none;
  }
  .imgAnaSucces {
    width: 80px;
  }
  .containerAnaSucces {
    top: 5%;
  }
  .successCC {
    left: -3% !important;
    top: 7% !important;
  }
  .CCAna {
    top: 3% !important;
  }
  /*  .marginImgHeader {
      margin-top: 17vw;
   } */
  .web {
    display: none !important;
  }
  .mob {
    display: block !important;
  }
  .ExtraIncomeMob {
    height: 401px;
  }
  .inputs-bottom-fn.Anio {
    margin-bottom: 6px !important;
    margin-top: 0 !important;
    border: none !important;
    width: 100% !important;
  }
  .inputs-bottom-fn {
    width: 100% !important;
  }
  .inputs-bottom {
    padding-right: 32px;
  }

  .imageweb {
    display: none;
  }

  .imagemob {
    display: block;
  }
  /*  .iconEdit {
      margin-left: 15% !important;
   } */
  .iconEditCommon {
    margin-left: -12% !important;
  }
  .motivoCred div:last-child {
    height: 60px;
  }
  .MuiOutlinedInput-inputMarginDense {
    padding-bottom: 18.5px !important;
  }
  .trails-text {
    height: 100%;
    line-height: 120%;
    font-size: 34px;
    font-weight: bold;
    margin-top: 9vw;
    /*margin-left: 20px;
        margin-top: 49px;*/
    margin-left: 0;
    text-align: left;
  }
  .text-funnelcc {
    font-size: 3vw;
  }
  .d-web {
    display: none;
  }
  .d-mob {
    display: list-item;
  }
  .paddle1 {
    display: none;
  }
  .paddle2 {
    display: none;
  }
  .paddle1cc {
    display: none;
  }
  .paddle2cc {
    display: none;
  }
  .paddle1ft {
    display: none;
  }
  .paddle2ft {
    display: none;
  }
  .form-web {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 768px) {
  .boxbutton {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .success-button {
    min-width: 100% !important;
    max-width: 100% !important;
    font-size: 16px;
    height: auto;
    padding: 9px 15px;
  }
  .pl-preferred-credit-card label {
    font-size: 14px !important;
  }
  .modalShare {
    width: 90% !important;
  }
  .closeDiv {
    top: -2.5vw;
    left: 37vw;
  }
}
@media (max-width: 767px) {
  .marginImgHeader {
    height: 239px;
    margin-top: 40px;
  }
  .trails-text {
    margin-top: 2vw;
  }
  .paddleft {
    padding-left: 20px !important;
  }
}

@media (max-width: 705px) {
  .Play {
    margin-left: 40%;
    margin-top: 409px;
  }
  .web {
    display: none;
  }
  .closeDiv {
    top: -2.5vw;
    left: 40vw;
  }
}
@media (max-width: 647px) {
  .closeDiv {
    top: -5vw;
    left: 44vw;
  }
}
@media (max-width: 600px) {
  .iconEdit1 {
    margin-left: 15% !important;
  }
  .closeDiv {
    top: -5vw;
    left: 48vw;
  }
  /*  .marginImgHeader {
      margin-top: 18vw;
   } */
  .trails-text {
    font-size: 20px;
  }
}

@media (max-width: 554px) {
  .iconEdit1 {
    margin-left: 10% !important;
  }
  .closeDiv {
    top: -5vw;
    left: 50vw;
  }
}
@media (max-width: 510px) {
  .iconEdit1 {
    margin-left: 5% !important;
  }
}
@media (max-width: 500px) {
  .display-web {
    display: none !important;
  }
  .display-mobile {
    display: block !important;
  }

  .display-mobile-flex {
    display: flex !important;
  }
  /* .marginImgHeader {
    margin-top: 20vw;
  } */
}
@media (max-width: 455px) {
  .iconEdit1 {
    margin-left: 5px !important;
  }
}
@media (max-width: 426px) {
  .home-header-mob {
    position: fixed;
    left: 0;
    width: 100%;
    padding: 0rem 31px;
    background-color: #fafafa;
    top: 0;
    z-index: 2;
  }

  .d-mobile {
    display: block;
  }
  .main-article-mob {
    position: absolute;
    padding-top: 1rem;
    z-index: 0;
  }
  .main-article-mob-img {
    display: flex;
    justify-content: flex-end;
  }
  .home-carousel {
    display: flex;
    flex-direction: column;
  }
  .home-carousel .home-carousel-item {
    width: 100% !important;
  }
}

@media (max-width: 417px) {
  .closeDiv {
    top: -5vw;
    left: 67vw;
  }
  .marginImgHeader {
    height: 200px;
    margin-top: 40px;
  }
}
@media (max-width: 410px) {
  .iconEdit1 {
    margin-left: -18% !important;
  }
}
@media (max-width: 375px) {
  .pl-preferred-credit-card label {
    font-size: 14px !important;
    margin-top: 4px;
  }
  .closeDiv {
    top: -5vw;
    left: 75vw;
  }
  /* .marginImgHeader {
      margin-top: 30%;
   } */
  .trails-text {
    margin-top: 0vw;
  }
}
@media (max-width: 354px) {
  .text-funnelcc {
    font-size: 4vw;
  }
  .closeDiv {
    top: -5vw;
    left: 100vw;
  }
  .trails-text {
    font-size: 20px;
  }
  /* .marginImgHeader {
    margin-top: 38%;
 } */
}
@media (max-width: 296px) {
  /* .marginImgHeader {
    margin-top: 150px;
 } */
}

/* #table */
.Table {
  display: table;
  margin: auto;
  font-size: 0.2em;
}

.Title {
  display: table-caption;

  text-align: center;

  font-weight: bold;

  font-size: larger;
}

.Heading {
  display: table-row;

  font-weight: bold;

  text-align: center;
}

.Row {
  display: table-row;
}

.Cell {
  display: table-cell;

  /* border-width: thin; */

  padding-left: 5px;

  padding-right: 5px;
}

.boxCard {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  height: 100px;
}
.boxCard div.amountCard {
  order: 2;
  flex: 0 1 auto;
  text-align: end;
  align-self: auto;
  min-width: 50%;
  min-height: auto;
  background: #f7f7f7;
  border-radius: 4px;
}
.boxCard div.concepto {
  order: 1;
  flex: 0 1 auto;
  text-align: start;
  align-self: auto;
  min-width: 50%;
  min-height: auto;
  background: #f7f7f7;
  border-radius: 4px;
}

.modalTestInversion {
  width: 500px;
  padding: 16px 32px 24px;
  overflow: auto;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  overflow-x: hidden;
  text-align: center;
  border-radius: 5px;
  background-color: #fff;
}

@media (max-width: 760px) {
  .modalTestInversion {
    width: 90% !important;
  }
}

.notificacion {
  animation: notificacion-header 2s infinite;
}

@keyframes notificacion-header {
  0% {
    -moz-box-shadow: 0 0 0 0 #d31e4d;
    box-shadow: 0 0 0 0 #d31e4d;
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(245, 176, 205, 0);
    box-shadow: 0 0 0 20px rgba(245, 161, 220, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(245, 161, 220, 0);
    box-shadow: 0 0 0 0 rgba(245, 161, 220, 0);
  }
}

@-webkit-keyframes notificacion-header {
  0% {
    -webkit-box-shadow: 0 0 0 0 #d31e4d;
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(245, 161, 220, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(245, 161, 220, 0);
  }
}

@media (min-width: 761px) {
  .BackHeader {
    background-position: left !important;
    background-size: cover !important;
  }
}

@media (max-width: 760px) {
  .BackHeader {
    background-position: left !important;
    background-size: 209% !important;
  }
}

@media (min-width: 761px) {
  .BackWO {
    background-position: left !important;
    background-size: cover !important;
  }
  .modalInactividad {
    width: 30%;
    padding: 16px 32px 24px;
    position: absolute;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    text-align: center;
    border-radius: 5px;
    background-color: #fafafa;
  }
  .heightext {
    height: 80px;
  }
}

@media (max-width: 760px) {
  .BackWO {
    background-position: left !important;
    background-size: cover !important;
  }
  .heightext {
    height: 100px;
  }
  .modalInactividad {
    width: 100%;
    top: 60% !important;
    left: 50% !important;
    padding: 16px 32px 24px;
    position: absolute;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    text-align: center;
    border-radius: 5px;
    background-color: #fafafa;
  }
}

.MuiSlider-rail,
.MuiSlider-track {
  height: 5px !important;
}

.MuiSlider-root {
  color: #f64282 !important;
}

.rec-carousel > .rec-arrow:hover:enabled,
.rec-arrow:focus:enabled {
  color: #f64282 !important;
}

.faceHome:hover {
  width: 90px;
  height: 109px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 5px solid #d31e4d;
}

.faceHome:hover > img {
  width: 50%;
}

.whatHome:hover {
  width: 141px;
  height: 165px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 5px solid #d31e4d;
}

.whatHome:hover > img {
  width: 70%;
}

.messangerHome:hover {
  width: 76.23px;
  height: 88.3px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 5px solid #d31e4d;
}

.messangerHome:hover > img {
  width: 70%;
}

.compartirHome:hover {
  width: 89.58px;
  height: 103.83px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 5px solid #d31e4d;
}

.compartirHome:hover > img {
  width: 70%;
}

@media (max-width: 760px) {
  .web {
    display: none;
  }
}

@media (min-width: 760px) {
  .web {
    display: block;
  }
}

.navbar {
  background: #fafafa;
}

.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer !important;
}

.WhidthAsesor {
  width: 90.594px !important;
  height: auto !important;
}

.WhidthAsesorMovile {
  width: 49.594px !important;
  height: 48.125px !important;
}

#AsesorDiv:hover > img {
  width: 49.594px !important;
  height: 48.125px !important;
}

#navTextHome {
  margin-left: auto;
  margin-right: 0px !important;
}

.text-hover:hover {
  font-weight: bold;
}

@media (max-width: 760px) {
  .paddingTop {
    padding-top: 20%;
  }
}
@media (min-width: 761px) {
  .paddingTop {
    padding-top: 8%;
  }
}

@media (min-width: 761px) {
  .marginleft {
    margin-left: -5% !important;
  }
}

@media (max-width: 760px) {
  .marginleft {
    margin-left: auto;
  }
}

.MuiStepIcon-root {
  color: transparent !important;
  border: 1px solid #07210a !important;
  border-radius: 8px !important;
  margin-bottom: 27% !important;
}

@media (min-width: 761px) {
  .widthRow {
    width: 70%;
    margin: auto;
    box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
}

@media (max-width: 760px) {
  .widthRow {
    width: 100%;
    margin: auto;
    box-shadow: 0px 2px 32px rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
}

@media (max-width: 760px) {
  #stepper {
    padding-bottom: 0px !important;
  }
}

.divCategorias {
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(166, 166, 166, 0.1);
  border-radius: 4px;
  padding: 5% 0%;
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

@media (max-width: 760px) {
  .paddingmobile {
    padding: 0px;
  }
}

.divEntrenamiento {
  padding: 4%;
  width: 95%;
  opacity: 5;
  margin-bottom: 17px;
}

@media (max-width: 760px) {
  .divEntrenamiento {
    width: 100%;
  }
}

.percent-style {
  background: url("../img/anacoach/por-ciento.svg") no-repeat right;
  background-position: 98% center !important;
  background-size: 14px;
}

.NoFocus:focus {
  outline: none !important;
}

.chartjs-render-monitor {
  width: 260px !important;
  height: auto !important;
  background: url("../img/anacoach/Training/Crono.svg") no-repeat;
  background-position-x: 55%;
  background-size: 205px;
  background-position-y: 2px;
  padding-top: 42px;
  margin-left: -22px;
  padding-bottom: 35px;
}

@media (max-width: 760px) {
  .chartjs-render-monitor {
    width: 190px !important;
    background-position-x: 56%;
    background-size: 150px;
    background-position-y: 14px;
    padding-top: 45px;
    margin-left: -13px;
    padding-left: 5px;
  }
  .coru-custom-tooltip .tooltip-text {
    left: calc(-220px+ 1em + 4px);
  }
}

.verification-vexi .MuiInputBase-root {
  background-color: transparent;
  letter-spacing: 12px;
}

.verification-vexi .MuiInput-underline {
  width: 70%;
  margin: auto;
}

.kandaOdometer {
  margin: 100px;
  font-size: 60px;
  color: #070b11;
  display: flex !important;
}
.containerNum1 {
  text-align: center;
  color: #070b1133;
  font-size: 80px;
  margin: auto 20px;
}
.containerNum1day {
  text-align: center;
  color: transparent;
  font-size: 80px;
  margin: auto 20px;
}
.containerNum1hour {
  text-align: center;
  color: transparent;
  font-size: 80px;
  margin: auto 20px;
}
.containerNum1min {
  text-align: center;
  color: #070b1133;
  font-size: 80px;
  margin: auto 20px;
}
.containerNum1sec {
  text-align: center;
  color: #070b1133;
  font-size: 80px;
  margin: auto 20px;
}
.containerNum {
  background: #ffffff;
  box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
    0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 16px;
  width: 160px;
  height: 160px;
  text-align: center;
  padding: 25px;
  color: #070b11;
  font-size: 60px;
  margin-top: 24px;
}
.etiquetaNum {
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 120%;
  align-items: center;
  text-align: center;
  color: #070b1133;
}
.containerNumflex {
  display: flex;
}

.countDownTimer {
  text-align: center;
  font-size: 50px;
}
.odometer-block {
  display: flex !important;
  gap: 50px;
}

@media (max-width: 769px) {
  .kandaOdometer {
    display: block !important;
    margin-left: 15%;
  }
  .etiquetaNum {
    margin-bottom: 20px;
  }
  .containerNum {
    background: #fff;
    box-shadow: 0 100px 80px rgb(0 0 0 / 7%),
      0 41.7776px 33.4221px rgb(0 0 0 / 5%),
      0 22.3363px 17.869px rgb(0 0 0 / 4%),
      0 12.5216px 10.0172px rgb(0 0 0 / 4%),
      0 6.6501px 5.32008px rgb(0 0 0 / 3%),
      0 2.76726px 2.21381px rgb(0 0 0 / 2%);
    border-radius: 16px;
    width: 75px;
    height: 75px;
    text-align: center;
    padding: 20px;
    color: #070b11;
    font-size: 26px;
    margin-top: 8px;
  }
  .countDownTimer {
    text-align: center;
    font-size: 14px;
  }
  .countDownTimer {
    padding: 10px 10px 10px 18px;
  }
  .odometer-block {
    display: flex !important;
    gap: 5px;
  }
}

.linkColor {
  color: #f64282 !important;
}
.linkColor:hover {
  color: #f64282 !important;
}
