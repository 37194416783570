.step-info-custom-progress-step {
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
}

.step-info-custom-progress-step .step-list-container {
    display: inline-flex;
    height: 100%;
    max-width: 70%;
    margin: 0 auto;
    overflow-x: auto;
}

/* ---- START Scrollbar ---- */
.step-info-custom-progress-step .step-list-container::-webkit-scrollbar {
    width: 20px;
    height: 15px;
}

/* Track */
.step-info-custom-progress-step .step-list-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.254); 
    border-radius: 10px;
}

/* Handle */
.step-info-custom-progress-step .step-list-container::-webkit-scrollbar-thumb {
    background: #ECECEC; 
    border-radius: 10px;
}

/* Handle on hover */
.step-info-custom-progress-step .step-list-container::-webkit-scrollbar-thumb:hover {
    background: #F64282; 
}

/* ---- END Scrollbar ---- */

.step-info-custom-progress-step .step-list-container .step-container {
    display: flex;
    justify-content: center;
}


.step-info-custom-progress-step .step-list-container .step-container .number {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 2px solid #ECECEC;
    color: #ECECEC;
    margin: auto;
    text-align: center;
}

.step-info-custom-progress-step .step-list-container .step-container .number.active {
    border: 2px solid #ECECEC;
    color: #222222;
    background-color: #ECECEC;
}

.step-info-custom-progress-step .step-list-container .step-container .number p {
    margin: 0;
    padding: 0;
    padding-bottom: 2px;
    font-weight: bold;
}

.step-info-custom-progress-step .step-list-container .step-container .line-cool {
    width: 35px;
    border: 0px;
    border-top: 2px solid #ECECEC;
    margin: auto 8px;
    border-style: dashed;
}

.step-info-custom-progress-step .coach-container button.coach {
    padding: .8em;
    position: absolute;
    right: 2em;
    bottom: calc(25px - .8em);
    border-radius: 25px;
    border: 0;
}

.step-info-custom-progress-step .coach-container button.coach:active {
    outline: 0 !important;
}

.step-info-custom-progress-step .coach-container button.coach:focus {
    outline: 0 !important;
}

.step-info-custom-progress-step .coach-container .coach img {
    width: 50px;
}

.step-info-custom-progress-step .coach-container .coach p{
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: .8em;
    font-weight: bold;
    margin-top: 3px;
    color: #222222;
    font-family: 'Roboto';
}

@media only screen and (max-width: 770px) {

    .step-info-custom-progress-step {
        height: 50px;
    }

    .step-info-custom-progress-step .step-list-container::-webkit-scrollbar {
        display: none;
    }
      
      /* Hide scrollbar for IE, Edge and Firefox */
    .step-info-custom-progress-step .step-list-container {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
    
    .step-info-custom-progress-step .coach-container button.coach {
        right: 1em;
    }

    .step-info-custom-progress-step .step-list-container {
        max-width: 50%;
    }

    .step-info-custom-progress-step .step-list-container .step-container .number {
        width: 28px;
        height: 28px;
    }

    .step-info-custom-progress-step .coach-container .coach img {
        width: 35px;
    }

}