/**  =====================
 Authentication css start
 ==========================  **/

.auth-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-width: 100%;
    min-height: 90vh;
    padding-top: 30px;
    /* padding-bottom: 120px; */
}

.auth-wrapper a, .auth-wrapper p>a {
    color: #111;
    font-weight: 600;
}

.auth-wrapper .btn-auth-gen .btn-icon {
    width: 140px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
}

.auth-wrapper .btn-auth-gen .btn-icon small {
    font-size: 15px;
}

.auth-wrapper .input-group {
    background: transparent;
}

.auth-wrapper .card {
    margin-bottom: 0;
}

.auth-wrapper>div {
    z-index: 5;
}

.auth-wrapper .auth-content {
    position: relative;
    width: 390px;
    padding: 15px;
    z-index: 5;
}

.auth-wrapper .auth-content.multyform, .auth-wrapper .auth-content.subscribe {
    width: 750px;
}

@media only screen and (max-width: 768px) {
    .auth-wrapper {
        max-width: 360px;
    }
}

@media only screen and (max-width: 575px) {
    .auth-wrapper .card .card-body {
        padding: 30px 15px;
    }
}

.auth-wrapper .auth-icon {
    font-size: 30px;
}

.auth-wrapper .auth-icon:before {
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
    background-clip: inherit;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.auth-wrapper.offline {
    background-image: none;
}

.auth-wrapper.offline:before {
    display: none;
}

.auth-wrapper .auth-bg .r {
    position: absolute;
    width: 230px;
    height: 230px;
    border-radius: 50%;
}

.auth-wrapper .auth-bg .r:first-child {
    top: -110px;
    right: -85px;
    background: linear-gradient(-135deg, #1de9b6 0%, #1dc4e9 100%);
}

.auth-wrapper .auth-bg .r:last-child {
    left: -80px;
    bottom: 10px;
    background: linear-gradient(-135deg, #899FD4 0%, #A389D4 100%);
}

.auth-wrapper .auth-bg .r.s {
    width: 20px;
    height: 20px;
}

.auth-wrapper .auth-bg .r.s:nth-child(2) {
    top: 150px;
    right: -50px;
    background: #04a9f5;
}

.auth-wrapper .auth-bg .r.s:nth-child(3) {
    left: -50px;
    bottom: 245px;
    background: #1de9b6;
}

.auth-wrapper .auth-bg .r:nth-child(odd) {
    animation: floating 7s infinite;
}

.auth-wrapper .auth-bg .r:nth-child(even) {
    animation: floating 9s infinite;
}

/* image variant start */

.aut-bg-img {
    background-size: cover;
    /*background-image: url('../../../images/bg-images/bg4.jpg');*/
    background-repeat: no-repeat;
    background-position: center;
}

.aut-bg-img-side p {
    line-height: 2;
}

.aut-bg-img .custom-select, .aut-bg-img-side .custom-select, .aut-bg-img .form-control, .aut-bg-img-side .form-control {
    background: transparent;
}

.auth-tabs .tab-content {
    overflow: hidden;
    position: relative;
}

.auth-tabs .tab-content .tab-pane>* {
    position: relative;
    z-index: 5;
}

.auth-tabs .tab-content .auth-icon {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 180px;
    transform: rotate(-30deg);
    z-index: 1;
    opacity: 0.2;
}

/* image varient start */

@keyframes floating {
    from {
        transform: rotate(0deg) translate(-10px) rotate(0deg);
    }
    to {
        transform: rotate(360deg) translate(-10px) rotate(-360deg);
    }
}

.btn {
    padding: 10px 20px;
    border-radius: 0.25rem;
    font-size: 14px;
    margin-bottom: 5px;
    margin-right: 10px;
    transition: all 0.3s ease-in-out;
}

.shadow-2 {
    box-shadow: 0 10px 18px 0 rgba(62, 57, 107, .2);
}

/**====== Authentication css end ======**/