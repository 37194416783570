.card-container-coru {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 3;
    left: 43%;
}

.card-container-coru div.card-multiple-desc {
    width: 286px;
    height: auto;
    background-color: #91d770;
    color: #07210a;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    padding: 10px;
}

.card-container-coru p,
.card-container-coru b {
    font-size: 15px;
    margin-bottom: 0;
}

.space-top {
    margin-top: 1.2rem;
}

@media (max-width: 768px) {
    
    .card-container-coru  {
        /* transform: translateX(-30px); */
        width: 92%;
    }

    .card-multiple-desc {
        margin-left: auto;
        margin-right: -10px;
    }

}