.helper-message {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.helper-message helper-message-icon {
    width: 10%;
}

.helper-message-icon {
    width: 32px;
    height: 32px;
    background: #6BD5AF;
    padding: 15px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.helper-message-icon img{
    width: 20px;
}

.helper-message-bubble-right {
    width: 90%;
    background: white;
    padding: 10px;
    border-radius: 16px;
    border-top-right-radius: 0;
}

.helper-message-bubble-right:after {
    content: '';
    
}