.helper-modal {
    width: 100%;
    height: 100%;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, .5);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: ease-in-out .5s;
}

.helper-modal-container {
    width: 50%;
    height: 85%;
    border-radius: 10px;
    background: white;
    background: #FAFAFA;
}

.helper-modal-header {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    background: #ECECEC;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 15%;
}

.helper-modal-header h3 {
    font-weight: bold;
}

button.helper-modal-btn-cerrar {
    background: none !important;
    border-color: transparent;
}

.hidden {
    display: none !important;
    transition: ease-in-out .5s;
}

.helper-modal-message-writter {
    height: 10%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: #FAFAFA;
}

.helper-modal-writter {
    width: 90%;
    height: 44px;
    border: 1px solid #A6A6A6;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
}

.helper-modal-message {
    background: none;
    border: none;
    width: 75%;
}

.helper-modal-submit {
    width: 20%;
    background: #6BD5AF;
    border: none;
    border-radius: 4px;
    margin: 5px;
}

.helper-modal-submit:hover {
    background: #31d196;
}

.helper-modal-message-container {
    height: 70%;
    padding: 15px;
}

@media(max-width:540px) {
    .helper-modal {
        justify-content: end;
        align-items: flex-end;
    }
    .helper-modal-message-container {
        height: 75%;
    }
    .helper-modal-container {
        width: 100%;
        height: 90%;
    }
}