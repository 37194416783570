.wo-layout-default {
    background-color: #FAFAFA;
    min-height: 100vh;
    overflow: hidden;
}

.wo-layout-default br {
    display: block;
    content: "";
    margin-bottom: 7em;
}


/* Layout Header Presentation Styles */

.wo-layout-default .wo-layout-header {
    padding-top: 8em;
    background-color: #ECECEC;
}


.wo-layout-default .wo-layout-header .header-content{
    max-width: 780px;
    margin: 0 auto;
    display: flex;
    padding-left: 2em;
    padding-right: 2em;
    margin-bottom: 3em;
    padding-bottom: 2em;
}

.wo-layout-default .wo-layout-header .text-c {
    width: 60%;
    padding-right: 2em;
}

.wo-layout-default .wo-layout-header .img-c {
    width: 40%;
    padding-right: 1em;
    padding-left: 5em;
}

.wo-layout-default .wo-layout-header .text-c .title {
    font-size: 2.5em;
    font-weight: bold;
    color: #222222;
}

.wo-layout-default .wo-layout-header .text-c .time-time-text {
    display: flex;
    font-size: 1.2em;
    color: #222222;
    padding-top: 1.5em;
}

.wo-layout-default .wo-layout-header .text-c .time-time-text p {
    font-size: 1em;
}

.wo-layout-default .wo-layout-header .text-c .time-time-text img {
    max-width: 20px;
    max-height: 20px;
    margin-right: .5em;
}

.wo-layout-default .wo-layout-header .img-c img {
    width: 100%;
    padding-bottom: 2em;
    padding-left: 2em;
    padding-right: 2em;
}


/* Layout Container */

.wo-layout-default .layout-container {
    max-width: 700px;
    margin: 0 auto;
    padding-left: 1.5em;
    padding-right: 1.5em;
    padding-bottom: 1.5em;
    overflow: hidden;
}

@media only screen and (max-width: 770px) {
    
    /* Layout Header Presentation Styles */

    .wo-layout-default .wo-layout-header {
        padding-top: 9em;
        background-color: #ECECEC;
    }

    .wo-layout-default .wo-layout-header.hidden-component {
        visibility: hidden;
        height: 0;
    }

    .wo-layout-default .wo-layout-header .header-content{
        padding-bottom: 1em;
        padding-left: 1.5em;
        padding-right: 1.5em;
    }

    .wo-layout-default .wo-layout-header .text-c {
        width: 60%;
        padding-right: 0;
    }

    .wo-layout-default .wo-layout-header .img-c {
        width: 40%;
        padding-right: 0;
        padding-left: 2em;
    }

    .wo-layout-default .wo-layout-header .text-c .title {
        font-size: 5vw;
    }

    .wo-layout-default .wo-layout-header .text-c .time-time-text {
        font-size: 3vw;
        padding-top: 1.5em;
    }

    .wo-layout-default .wo-layout-header .text-c .time-time-text p {
        font-size: 1em;
    }

    .wo-layout-default .wo-layout-header .text-c .time-time-text img {
        max-width: 20px;
        max-height: 20px;
        margin-right: .5em;
    }

    .wo-layout-default .wo-layout-header .img-c img {
        width: 100%;
        padding-bottom: 2em;
        padding-left: 2em;
        padding-right: 2em;
    }
    
}