.hidden {
  display: none;
}
.yes {
  display: "block";
}

.cardOne {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /*position: absolute;*/
  z-index: 3;
  left: 0;
  animation-name: "";
  /*animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;*/
}
.cardOne1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /*position: absolute;*/
  z-index: 3;
  left: 0;
  animation-name: "";
  /*animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;*/
  margin-top: 35px;
}

@keyframes slidein {
  from {
    margin-left: 50%;
  }

  to {
    margin-left: 0%;
  }
}

@keyframes slidein2 {
  from {
    margin-left: -50%;
  }

  to {
    margin-left: 0%;
  }
}
.topOne1 {
  /*margin-top: 35px;*/
  margin-top: 20px !important;
}

.topOne1Init {
  margin-top: 20px !important;
}

.topOne1CC {
  margin-top: 20px;
}

/* .cardOne img {
    width: 20%;
    position: absolute;
    z-index: 1;
    margin-left: -10%;
    margin-top: -3%;
} */
.VideoIntoCardOne div {
  width: 100% !important;
  padding: 0 !important;
}
.cardOne div {
  padding: 26px;
  width: 288%;
  height: auto;
  background-color: #a6a6a61f;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  /*-webkit-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    border: 0px solid #000000;
    opacity: 0.12;*/
  /*clip-path: polygon(10% 0%, 100% 1%, 100% 100%, 10% 100%, 0% 50%);*/
  /*display: flex;*/
  justify-content: left;
  align-items: center;
  margin-left: 0px;
  /* padding-right: 8px;
    padding-left: 0.5vw; */
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-size: 14px;
}
.cardOne1 div {
  width: 100%;
  height: auto;
  background-color: #a6a6a61f;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  /*-webkit-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    border: 0px solid #000000;
    opacity: 0.12;*/
  /*clip-path: polygon(10% 0%, 100% 1%, 100% 100%, 10% 100%, 0% 50%);*/
  /*display: flex;*/
  justify-content: left;
  align-items: center;
  margin-left: 0px;
  padding-right: 8px;
  padding-left: 0.5vw;
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  font-size: 14px;
}

/*.cardOne div {
    width: 286px;
    height: auto;
    background-color: #a6a6a61f;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    /*-webkit-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    border: 0px solid #000000;
    opacity: 0.12;*/
/*clip-path: polygon(10% 0%, 100% 1%, 100% 100%, 10% 100%, 0% 50%);*/
/*display: flex;*/
/*justify-content: left;
    align-items: center;
    margin-left: 0px;
    padding-right: 8px;
}*/

.cardOneMargin div {
  margin-left: 0px !important;
}

.cardOne p {
  font-family: "Roboto", sans-serif !important;
  font-style: normal;
  /*font-family: Eina,"Helvetica Neue",Helvetica,sans-serif;*/
  font-size: 16px;
  /*  padding-left: 18px;
    margin-top: 1rem; */
  color: #07210a;
  margin-bottom: 0px;
}

@keyframes cardOne {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: translateX(30px);
  }
}
.hora {
  color: #110e20;
  float: right;
  font-size: 12px;
  margin-right: 5px;
  margin-top: -18px;
  font-weight: 400;
}
.bold-p {
  font-weight: 600;
}
.cardTwo {
  width: 272px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /*position: absolute;*/
  z-index: 3;
  /*top: 450px;*/
  left: 43%;
  animation-name: cardTwo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.cardTwoResp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /*position: absolute;*/
  z-index: 3;
  /*top: 450px;*/
  left: 43%;
  animation-name: none;
  /*animation-name: cardTwo;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;*/
}

.topTwo1 {
  /*     margin-top: 35px;*/
  margin-top: 20px !important;
  margin-bottom: 0;
}

.topTwo2 {
  top: 650px;
}

.cardTwo img {
  width: 20%;
  position: absolute;
  z-index: 1;
  margin-left: 86%;
  margin-top: -3%;
}

.cardTwo div {
  width: 50%;
  height: auto;
  background-color: white;
  border-radius: 12px 12px 40px 12px;
  -moz-border-radius: 12px 12px 40px 12px;
  -webkit-border-radius: 12px 12px 40px 12px;
  -webkit-box-shadow: 0px 0px 7px 2px rgba(184, 184, 184, 1);
  -moz-box-shadow: 0px 0px 7px 2px rgba(184, 184, 184, 1);
  box-shadow: 0px 0px 7px 2px rgba(184, 184, 184, 1);
  border: 0px solid #000000;
  opacity: 0.7;
  /*clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);  */
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: 38%;
}

.cardTwo p {
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 15px;
  padding-left: 10px;
  margin-top: 1rem;
}

.cardTwoResp img {
  width: 20%;
  position: absolute;
  z-index: 1;
  margin-left: 86%;
  margin-top: -3%;
}

.cardTwoResp div {
  width: 286px;
  height: auto;
  background-color: #91d770;
  color: #07210a;
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  /*-webkit-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    -moz-box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    box-shadow: 0px 0px 7px 2px rgba(184,184,184,1);
    border: 0px solid #000000; */
  /*opacity: 0.7;*/
  /*clip-path: polygon(0% 0%, 90% 0, 100% 50%, 90% 100%, 0% 100%);  */
  display: flex;
  justify-content: right;
  align-items: center;
  margin-left: auto;
  margin-right: -10px;
  /*transform: rotate(-180deg);*/
}

.cardTwoResp p {
  font-family: Eina, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 15px;
  padding-left: 10px;
  margin-top: 1rem;
}

@keyframes cardTwo {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: translateX(-30px);
  }
}

@media (max-width: 320px) {
  .cardTwoResp div {
    width: 250px;
  }
  .cardOne div {
    width: 211px !important;
    margin-left: -30px;
  }
  .cardOne1 div {
    width: 100% !important;
    margin-left: 0px;
  }
}

@media (min-width: 360px) {
  .cardOne {
    /* width: 290px; */
    /*margin-left: -30px;*/
    margin-right: auto;
  }
  .cardOne1 {
    width: 100%;
    margin-left: 0px;
    margin-right: auto;
  }
  .cardTwo {
    width: 290px;
  }

  .cardOne.inactividad {
    width: auto;
    margin-left: -30px;
    margin-right: auto;
  }
}
@media (min-width: 375px) {
  .cardOne {
    width: 100%;
    /*margin-left: -30px;*/
    margin-right: auto;
  }
  .cardOne1 {
    width: 100%;
    margin-left: 0px;
    margin-right: auto;
  }
  .cardTwo {
    width: 312px;
  }
}
@media (min-width: 481px) {
  .topTwo1 {
    margin-left: auto;
    margin-right: auto;
  }
  .topOne1 {
    margin-left: auto;
    margin-right: auto;
  }
  .cardTwo {
    width: 420px;
  }
  .cardTwoResp {
    width: 95%;
  }
  .cardOne {
    /*width: 420px;
    /*margin-left: -30px;*/
  }
  .cardOne1 {
    width: 100%;
    margin-left: 0px;
  }
}
@media (max-width: 768px) {
  .cardTwoResp {
    /* transform: translateX(-30px); */
    width: 92%;
  }
  .VideoIntoCardOne div {
    margin-top: 20px;
  }
}
@media (max-width: 720px) {
  .cardOne div {
    width: 100%;
  }

  .cardOne1 div {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .cardOne {
    width: 100%;
    animation-fill-mode: none;
    margin-left: 0;
  }

  .cardOne div {
    margin-left: 0;
  }
  /* .cardOne img {
        width: 12%;
        margin-left: -4%;
    } */
  .cardTwoResp {
    width: 100%;
    animation-fill-mode: none;
    margin-right: 0;
  }
  .cardTwoResp div {
    margin-right: 0;
  }
  .cardOne1 {
    width: 515px;
    animation-fill-mode: none;
    margin-left: 0;
    padding: 2%;
  }
}

@keyframes cardPrincipal {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
    transform: translateX(-5px);
  }
}
.cardPrincipal1 {
  /*animation-name: cardPrincipal;
    animation-duration: 1s;*/
  /*animation-timing-function: linear;
    animation-fill-mode: forwards;*/
}
